import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useGetParams } from '@hooks/envelopes/useGetParams';
import { closeLoader, showLoader } from '@store/modules/loader';
import { getEnvelope, getEnvelopes } from '@store/modules/envelopes';
import { catchThunkError } from '@utils/utils';

export const useLoadEnvelopes = () => {
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(state => state.user)

  const {
    direction,
    boxOguid,
    folder,
    page,
    perPage,
    oguid
  } = useGetParams()

  const loadEnvelopes = (): void => {
    dispatch(showLoader())

    !folder &&
      dispatch(getEnvelopes({ direction, boxOguid, page, perPage })).unwrap()
        .catch(catchThunkError)
        .finally(() => dispatch(closeLoader()))
  }

  const loadCurrentEnvelope = () => {
    dispatch(showLoader())
    const boxOguid = profile.boxOguid

    oguid && boxOguid && dispatch(getEnvelope({ oguid, boxOguid })).unwrap()
      .catch(catchThunkError)
      .finally(() => dispatch(closeLoader()))
  }

  return { loadEnvelopes, loadCurrentEnvelope }
}
