import { useLocation, useNavigate } from 'react-router-dom';

import {
  EnvelopeAllowedActionsButton,
  EnvelopeAllowedActionsName,
  BrowserStorage, isDevelopment
} from '@const/consts'
import { Path } from '@const/paths';
import {  displayConfirmNotification, displayErrorNotification } from '@utils/utils';

import EnvelopesService from '@services/envelopes'
import SharedService from '@services/shared'

import { IHandleAllowedAction } from '@views/envelopes/types';
import { IConfirmNotificationOptions } from '@utils/types';
import { useLoadEnvelopes } from '@hooks/envelopes/useLoadEnvelopes';
import { useTranslation } from 'react-i18next';

export const useAllowedActions = () => {
  const { t } = useTranslation(['envelope'])
  const navigate = useNavigate()
  const location = useLocation()
  const { loadCurrentEnvelope, loadEnvelopes } = useLoadEnvelopes()
  const { pathname, search } = location

  const getSharedLink = (sharedOguid: string) =>
    isDevelopment
      ? `https://f-shared-gd-main.dev.info-logistics.eu/${sharedOguid}`
      : `https://shared.${window.location.hostname}/${sharedOguid}`


  const handleClickAllowedActions = ({ option, oguid, subject, isCurrentEnvelope = false }: IHandleAllowedAction): void => {
    const { name } = option

    const openValues: string[] = [
      EnvelopeAllowedActionsName.OPEN,
      EnvelopeAllowedActionsButton.VIEW
    ]

    const signedActionsValues: string[] = [
      EnvelopeAllowedActionsButton.APPROVE,
      EnvelopeAllowedActionsButton.SIGN,
      EnvelopeAllowedActionsName.DECLINE
    ]

    const isOpen = openValues.includes(name)
    const isSignedAction = signedActionsValues.includes(name)
    const isStopProcess = name === EnvelopeAllowedActionsName.STOP_PROCESS

    if (isOpen && oguid) {
      const url = pathname + search
      localStorage.setItem(BrowserStorage.LAST_URL, url)

      const path = Path.ENVELOPE.replace(':oguid', oguid)
      navigate(path)
    }

    if (isStopProcess && oguid) {
      const notify: IConfirmNotificationOptions = {
        title: t('envelope:notification:stopProcessTitle'),
        content: `${t('envelope:notification:stopProcessContent')} "${subject}"?`,
        theme: 'danger',
        onSubmit: () => {
          EnvelopesService.postEnvelopeFlowStop(oguid)
            .then(() => {
              isCurrentEnvelope ? loadCurrentEnvelope() : loadEnvelopes()
            })
            .catch(displayErrorNotification)
        }
      }

      displayConfirmNotification(notify)
    }

    if (isSignedAction && oguid) {
      SharedService.getSharedOguid(oguid)
        .then(({ data }) => {
          window.open(getSharedLink(data), '_blank')
        })
        .catch(displayErrorNotification)
    }
  }

  return {
    handleClickAllowedActions
  }
}
