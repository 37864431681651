import React, { FC } from 'react';

import { getDateFormat, getLocaleDateFormat } from '@utils/utils';
import { DateFormat } from '@const/consts';

import { useAppSelector } from '@hooks/redux';

import { IEnvelopeLastChangeProps as IProps } from '@views/envelopes/types';

const EnvelopLastChange: FC<IProps> = ({ lastActionDateTime }) => {
  const { language } = useAppSelector(state => state.utils)

  return (
    <td>
      <div>
        { getLocaleDateFormat(lastActionDateTime, language) }
      </div>
      <div className='text-muted'>
        { getDateFormat(lastActionDateTime, DateFormat.TIME_ONLY) }
      </div>
    </td>
  )
}

export default EnvelopLastChange
