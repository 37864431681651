import { Instance } from '@const/consts'

const domain = 'https://greendocs.infologistics.'
const domainBR = 'https://greendocs.ilsbrasil.'

export const dropdownItems = [
  {
    name: Instance.GLOBAL,
    translation: domain + Instance.GLOBAL
  },
  {
    name: Instance.BR,
    translation: domainBR + Instance.BR
  },
  {
    name: Instance.RU,
    translation: domain + Instance.RU
  },
  {
    name: Instance.ZA,
    translation: domain + Instance.ZA
  }
]
