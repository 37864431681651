import { getDateFormat, getLocaleDateFormat } from '@utils/utils';
import {
  DateFormat,
  EnvelopeAllovedActions,
  EnvelopeAllowedActionsButton,
  EnvelopeAllowedActionsName, FlowStageState,
} from '@const/consts';

import { ILibDropdownItem, Nullable } from '@infologistics/frontend-libraries';

import {
  EnvelopeSettingsElement,
  ICardInfoSettingsArg,
  ICheckedAllowedActions,
} from '@views/envelopes/types';
import {
  IEnvelopeTasks,
} from '@store/modules/envelopes/types';

export const getCardInfoSettings = (
  { oguid, sender, flowStartDateTime, lastActionDateTime, textAt }: ICardInfoSettingsArg,
  locale: string
): EnvelopeSettingsElement[] => {
  const { boxEmail, user: { fullName } } = sender

  const getDate = (value: string): string =>
    `
      ${ getLocaleDateFormat(value, locale) }
      ${ textAt }
      ${ getDateFormat(value, DateFormat.HOUR_AND_MINUTES) }
    `

  return [
    {
      title: 'envelopes:card:idDocument',
      content: oguid
    },
    {
      title: 'envelopes:card:sender',
      content: boxEmail,
      additionalContent: fullName
    },
    {
      title: 'envelopes:card:sent',
      content: getDate(flowStartDateTime)
    },
    {
      title: 'envelopes:card:lastChanged',
      content: getDate(lastActionDateTime)
    }
  ]
}

export const getRecipientsParametersSettings = (task: IEnvelopeTasks): Nullable<EnvelopeSettingsElement[]> => {
  const result: EnvelopeSettingsElement[] = []

  const { recipient, state, completedEvidences, completedSigner } = task

  const {
    isRequireSelfie,
    isRequireSms,
    declaredSigner
  } = recipient

  const isCompleted = state === FlowStageState.COMPLETED
  const signer = completedSigner ?? declaredSigner

  signer?.position &&
    result.push({
      title: 'envelope:paramsPopover:position',
      content: signer?.position
    })

    signer?.initials &&
    result.push({
      title: 'envelope:paramsPopover:initials',
      content: signer?.initials
    })

    signer?.phone &&
    result.push({
      title: 'envelope:paramsPopover:phone',
      content: signer?.phone
    })

  if (!isCompleted) {
    isRequireSms &&
      result.push({
        title: 'envelope:paramsPopover:smsConfirmation',
        additionalContent: 'envelope:paramsPopover:required'
      })

    isRequireSelfie &&
      result.push({
        title: 'envelope:paramsPopover:selfie',
        additionalContent: 'envelope:paramsPopover:required'
      })
  } else {
    if (completedEvidences) {
      const {
        isVerifiedBySms,
        phone,
        isSelfieProvided,
        geolocation
      } = completedEvidences

      isVerifiedBySms &&
        result.push({
          title: 'envelope:paramsPopover:smsConfirmation',
          icon: 'IconCheck',
          content: phone
        })

      isSelfieProvided &&
        result.push({
          title: 'envelope:paramsPopover:selfie',
          icon: 'IconCheck',
          additionalContent: 'envelope:paramsPopover:loaded',
        })

      geolocation &&
        result.push({
          title: 'envelope:paramsPopover:geolocation',
          content: geolocation,
        })
    }
  }

  return result.length ? result : null
}

export const checkAllowedActions = (allowedActions: EnvelopeAllovedActions[]): ICheckedAllowedActions => {
  const checkAllowedActionsIncludes = (value: EnvelopeAllovedActions): boolean =>
    allowedActions.includes(value)

  return {
    haveDeclineSign:
      checkAllowedActionsIncludes(EnvelopeAllovedActions.DECLINE_SIGN),
    haveDeclineApprove:
      checkAllowedActionsIncludes(EnvelopeAllovedActions.DECLINE_APPROVE),
    haveStopProcess:
      checkAllowedActionsIncludes(EnvelopeAllovedActions.STOP_PROCESS),
    haveSign:
      checkAllowedActionsIncludes(EnvelopeAllovedActions.SIGN),
    haveApprove:
      checkAllowedActionsIncludes(EnvelopeAllovedActions.APPROVE)
  }
}

export const getAllowedAction = (checkedAllowedActions: ICheckedAllowedActions): EnvelopeAllowedActionsButton => {
  if (checkedAllowedActions.haveSign)
    return EnvelopeAllowedActionsButton.SIGN

  return (
    checkedAllowedActions.haveApprove ?
      EnvelopeAllowedActionsButton.APPROVE :
      EnvelopeAllowedActionsButton.VIEW
  )
}

export const getOptionsAllowedAction = (isCardEnvelope: boolean, checkedAllowedActions: ICheckedAllowedActions): ILibDropdownItem[] => {
  const options: ILibDropdownItem[] = []

  if (!isCardEnvelope) {
    options
      .push({
        name: EnvelopeAllowedActionsName.OPEN,
        translation: 'common:open'
      })
  }

  if (
    checkedAllowedActions.haveDeclineSign ||
    checkedAllowedActions.haveDeclineApprove
  )
    options
      .push({
        name: EnvelopeAllowedActionsName.DECLINE,
        translation: 'common:decline'
      })

  if (checkedAllowedActions.haveStopProcess)
    options
      .push({
        name: EnvelopeAllowedActionsName.STOP_PROCESS,
        translation: 'envelopes:actions:stopProcess'
      })

  return options
}
