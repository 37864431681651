import { AxiosResponse } from 'axios'
import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { ICurrentUser, IUpdateProfile, IUserSettings } from '@store/modules/user/types'

class UsersService extends AbstractHttpService {
  private readonly url = urls.users

  async getCurrentUser (): Promise<AxiosResponse<ICurrentUser>> {
    const url = this.url.current

    return await this._http.get(url, {})
  }

  async updateUser (profile: IUpdateProfile): Promise<AxiosResponse> {
    const url = this.url.current

    return await this._http.put(url, profile, {})
  }

  async requestAPIKey (): Promise<AxiosResponse> {
    const url = this.url.generateAPIKey

    return await this._http.post(url, null, {})
  }

  async getUserSettings (): Promise<AxiosResponse<IUserSettings>> {
    const url = this.url.settings

    return await this._http.get(url)
  }

  async updateUserSettings (userSettings: IUserSettings): Promise<AxiosResponse> {
    const url = this.url.settings

    return await this._http.put(url, userSettings)
  }
}

export default new UsersService()

