import { useTranslation } from 'react-i18next';

import { displayErrorNotification, getLanguageFromInstance } from '@utils/utils';
import { setLanguage } from '@store/modules/utils';
import { useAppDispatch } from '@hooks/redux';

import { LocalStorage } from '@const/consts';

import { ICurrentUser } from '@store/modules/user/types';
import { ProfileInitialValues } from '@views/profile/types';

export const useUpdateLanguage = () => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const updateLanguage = (profile: ICurrentUser | ProfileInitialValues | void) => {
    if (!profile) return

    const { locale } = profile
    const language = getLanguageFromInstance(locale)

    return i18n
      .changeLanguage(language)
      .then(() => {
        localStorage.setItem(LocalStorage.LANGUAGE, language)
        dispatch(setLanguage(language))
      })
      .catch(displayErrorNotification)
  }

  return {
    updateLanguage
  }
}
