import { DraftEnvelopeRecipientType } from '@store/modules/envelopes/types'
import { IParams } from '@app/types'

export const isDevelopment =
  process.env.REACT_APP_API_ENV === 'development'

export const BASE_URL =
  isDevelopment
    ? 'https://api-gd-backend-dev.dev.info-logistics.eu/'
    : `https://api.${window.location.hostname}/`

export const SuccessCode = {
  GET: 200,
  POST: [200, 201],
  PUT: [200, 201, 204],
  DELETE: [200, 202, 204]
}

export const Languages = {
  EN: 'en',
  PT: 'pt',
  RU: 'ru'
}

export enum LocaleType {
  EN = 'en_US',
  PT = 'pt_PT',
  RU = 'ru_RU'
}

export enum LocalStorage {
  LANGUAGE = 'i18nextLng',
  LAST_URL = 'last_url'
}

export enum BrowserStorage {
  INSTANCE = 'instance',
  LANGUAGE = 'i18nextLng',
  LAST_URL = 'last_url',
  PASSWORD_KEY = 'p_key',
  TOKEN_TAB = 't_tab',
  TOKEN_TABS = 't_tabs',
  TOKEN_IS_REMEMBER = 't_is_remember',
  TOKEN_ACCESS = 't_access',
  TOKEN_REFRESH = 't_refresh',
  TOKEN_USER = 't_user',
}

export const lookupSessionStorage = 'baseUrl'

export const NS = [
  'auth',
  'common',
  'currency',
  'envelopes',
  'error',
  'language',
  'notification'
]

export enum Space {
  EM = '\u2003',
  EN = '\u2002',
  NON_BREAK = '\u00a0',
  STANDARD = '\u0020',
  THIN = '\u2009'
}

export const EXECUTE_INTERVAL = 60000
export const MAX_TOKEN_CASH_AGE = 10000;
export const NAV_ANIMATION_DELAY = 100

export const ResponseCode = {
  DEL: 204,
  GET: 200,
  NO_CONTENT: 204,
  POST: 201,
  PUT: 201,
  ERROR: 400
}

export const ErrorCode = {
  NOT_AUTH: 401,
  NOT_FOUND: 404
}

export enum BooleanValue {
  FALSE = 'false',
  TRUE = 'true'
}

export enum UserField {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword'
}

export enum EnvelopeField {
  COMMENT = 'comment',
  EXPIRATION_DATE_TIME = 'expirationDateTime',
  EXTERNAL_ID = 'externalId',
  SUBJECT = 'subject'
}

export enum RecipientField {
  BOX_EMAIL = 'boxEmail',
  COLOR = 'color',
  FULL_NAME = 'signer.fullName',
  GROUP_NUM = 'groupNum',
  GUEST_EMAIL = 'guestEmail',
  INITIALS = 'signer.initials',
  IS_ALLOW_ELECTRONIC_SIGNATURE = 'signingParams.isAllowElectronicSignature',
  IS_HOST_MODE = 'isHostMode',
  IS_REQUIRE_SELFIE = 'isRequireSelfie',
  IS_REQUIRE_SMS = 'isRequireSms',
  PHONE = 'signer.phone',
  PHONE_OVERRIDE = 'phoneOverride',
  POSITION = 'signer.position',
  SIGNER_TYPE = 'signingParams.signerType',
  TYPE = 'type'
}

export enum MouseEventType {
  AUXCLICK = 'auxclick',
  CLICK = 'click'
}

export enum WindowEventType {
  BEFORE_UNLOAD = 'beforeunload',
}

export const LocalStorageKeysForSaveBetweenSessions: string[] = [
  BrowserStorage.LANGUAGE,
  BrowserStorage.TOKEN_TABS
]

export const SessionStorageKeysForSaveBetweenSessions: string[] = [
  BrowserStorage.TOKEN_TAB
]

export const needResetParams: IParams = { isNeedResetError: true }

export enum Direction {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}

export enum EnvelopeDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}

export enum EnvelopeAllovedActions {
  STOP_PROCESS = 'STOP_PROCESS',
  SIGN = 'SIGN',
  DECLINE_SIGN = 'DECLINE_SIGN',
  APPROVE = 'APPROVE',
  DECLINE_APPROVE = 'DECLINE_APPROVE'
}

export enum EnvelopeAllowedActionsButton {
  SIGN = 'SIGN',
  APPROVE = 'APPROVE',
  VIEW = 'VIEW'
}

export enum EnvelopeAllowedActionsName {
  OPEN = 'open',
  DECLINE = 'decline',
  STOP_PROCESS = 'stop_process'
}

export enum EnvelopeFlowState {
  IN_PROGRESS = 'IN_PROGRESS',
  SOLVED = 'SOLVED',
  DECLINED = 'DECLINED',
  STOPPED = 'STOPPED',
  EXPIRED = 'EXPIRED'
}

export enum EnvelopeFlowResult {
  SOLVED = 'SOLVED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED'
}

export enum TasksStatus {
  APPROVAL = 'APPROVAL',
  ACQUAINTANCE = 'ACQUAINTANCE',
  SIGNING = 'SIGNING'
}

export enum DateFormat {
  FULL_DATE_SLASH = 'MM/dd/yyyy',
  FULL_DATE_DOTS = 'MM.dd.yyyy',
  TIME_ONLY = 'HH:mm:ss',
  HOUR_AND_MINUTES = 'HH:mm',
  DATE_FULL_YEAR_DATEPICKER = 'dd.MM.yyyy',
}

export enum FileType {
  ORIGINAL = 'ORIGINAL',
  SIGNED = 'SIGNED',
  PROTOCOL = 'PROTOCOL'
}

export enum FlowStageState {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum RecipientAccessAuthorizedBy {
  ANONYMOUS = 'ANONYMOUS',
  TOKEN = 'TOKEN',
  GUEST_TOKEN = 'GUEST_TOKEN',
  EMAIL = 'EMAIL'
}

export const DraftEnvelopeRecipientTypes = [
  DraftEnvelopeRecipientType.SIGNING,
  DraftEnvelopeRecipientType.APPROVAL,
  DraftEnvelopeRecipientType.ACQUAINTANCE
]

export enum DraftEnvelopeRecipientTypeIcon {
  ACQUAINTANCE = 'IconStateQuestion',
  APPROVAL = 'IconStateApproving',
  SIGNING = 'IconStateSigning'
}

export enum ProfileFields {
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  FULL_NAME = 'fullName',
  INITIALS = 'initials',
  PHONE = 'phone',
  LOCALE = 'locale',
  EMAIL = 'email',
  TIME_ZONE = 'timeZone',
  PASSWORD = 'password',
  COMPANY_NAME = 'companyName',
  COMPANY_ZIP = 'companyZip',
  COMPANY_ADDRES = 'companyAddress',
  POSITION = 'position',
  IS_EMAIL_NOTIFICATION = 'isEmailNotification',
  IS_DEVICE_NOTIFICATION = 'isDeviceNotification',
  NOTIFICATION_LEVEL = 'notificationLevel',
  ELECTRONIC_SIGNATURE_IMAGE = 'electronicSignatureImage'
}

export enum ProfilePasswordFields {
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export enum PhoneVerificationValues {
  IS_PHONE_CONFIRMED = 'isPhoneConfirmed',
  PHONE_CHECK_TRAIN_ID = 'phoneCheckTranId',
}

export enum NotificationLevel {
  ONLY_INCOMING = 'ONLY_INCOMING',
  INCOMING_AND_FINISHED = 'INCOMING_AND_FINISHED'
}

export enum Modal {
  CHANGE_PASSWORD = 'ChangePassword',
  API_ACCESS_TOKEN = 'APIAccessToken',
  FIELD_VALUE = 'fieldValue',
  SIGNATURE_IMAGE_DRAW = 'SignatureImageDraw',
  SIGNATURE_IMAGE_UPLOAD = 'SignatureImageUpload',
  WEB_HOOKS = 'WebHooks'
}

export enum ProfileWebHookFields {
  IS_WEBHOOK_ENABLE = 'isWebhookEnabled',
  WEBHOOK_URL = 'webhookUrl',
  WEBHOOK_AUTH_TYPE = 'webhookAuthType',
  WEBHOOK_AUTH_TOKEN = 'webhookAuthToken',
  WEBHOOK_AUTH_EVENTS = 'webhookEvents'

}

export enum WebhookAuthType {
  NOAUTH = 'NOAUTH',
  BASIC = 'BASIC',
  BEARER = 'BEARER',
  X_API_KEY = 'X-API-KEY'
}

export enum WebhookEvents {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  SIGNED = 'SIGNED',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  ACQUAINTED = 'ACQUAINTED',
  STOPPED_PROCESS = 'STOPPED_PROCESS'
}

export const DraftEnvelopeRecipientsBaseColors = [
  '244, 67, 54',
  '233, 30, 99',
  '56, 39, 176',
  '103, 58, 183',
  '63, 81, 181',
  '33, 150, 243',
  '3, 169, 244',
  '0, 188, 212',
  '0, 150, 136',
  '76, 175, 80',
  '139, 195, 74',
  '205, 220, 57',
  '255, 193, 7',
  '255, 152, 0',
  '255, 87, 34',
  '121, 85, 72'
]

export const DRAFT_ENVELOPE_SIGNATURE_BASE_SIZE = 66

export const DRAFT_ENVELOPE_SIGNATURE_COEFFICIENT = 0.0351

export const DraftEnvelopeTextMarkSize = {
  WIDTH: 640,
  HEIGHT: 320
}

export const fileSizeLimitBytes = 1.049e8

export const imageSizeLimitBytes = 204800

export const cutSize = -40

export const ASIDE_WIDTH_SET_DELAY = 100

export enum DraftEnvelopePrepositionedMarkType {
  SIGNATURE = 'SIGNATURE',
  INITIALS = 'INITIALS',
  FULLNAME = 'FULLNAME',
  POSITION = 'POSITION',
  CHECKBOX = 'CHECKBOX',
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  STRING = 'STRING'
}

export enum DraftEnvelopePrepositionedMarkFont {
  ARIAL = 'ARIAL',
  /*HELVETICA = 'HELVETICA',*/ // Removed until clarification
  TIMES = 'TIMES'
}

export enum DraftEnvelopePrepositionedMarkFontSize {
  MAX = 26,
  MIN = 3
}

export enum DraftEnvelopePrepositionedMarkFontType {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  REGULAR = 'REGULAR'
}


export enum DraftEnvelopePrepositionedMarkScale {
  MAX = 210,
  MIN = 50
}


export enum PageSize {
  LANDSCAPE = 990,
  PORTRAIT = 700
}

export enum Instance {
  GLOBAL = 'global',
  RU = 'ru',
  BR = 'com.br',
  ZA = 'co.za'
}

export enum PhoneVerifyCountry {
  RU = 'ru',
  BR = 'br',
  GLOBAL = 'za',
  ZA = 'za'
}

export const DraftEnvelopePrepositionedMarkColors = [
  '#3276B1',
  '#739E73',
  '#57889C',
  '#B94A48',
  '#07798B',
  '#8A8A8A',
  '#333333',
  '#C79121',
  '#DFE67E',
  '#7BCBE3'
]

export const COPYRIGHT = 'ILS LLC. All rights reserved.'
