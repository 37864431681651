export const RouteName = {
  DEFAULT: '/',
  SIGN_IN: '/login',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_CONFIRM: '/password-reset-confirm',
  REGISTER: '/register',
  ENVELOPE: '/envelope',
  ENVELOPES: '/envelopes',
  PROFILE: '/profile',
}

export const Section = {
  ENVELOPES_BOX_OGUID: `${RouteName.ENVELOPES}/:boxOguid`,
  ENVELOPES_FILTER: `${RouteName.ENVELOPES}/filters`
}

export const Path = {
  ENVELOPE: `${RouteName.ENVELOPE}/:oguid`,
  ENVELOPES_FAST_ACCESS: `${RouteName.ENVELOPES}/:directionParam`,
  ENVELOPES_BOX_OGUID: `${Section.ENVELOPES_BOX_OGUID}/:directionOrFolder`,
  ENVELOPES_ALL: `${RouteName.ENVELOPES}/all`,
  ENVELOPE_NEW: `${RouteName.ENVELOPE}/new`,
  ENVELOPES_FILTER: `${Section.ENVELOPES_FILTER}/:oguid`,
  REGISTER: RouteName.REGISTER,
  PASSWORD_RESET: RouteName.PASSWORD_RESET,
  PASSWORD_RESET_CONFIRM: RouteName.PASSWORD_RESET_CONFIRM,
  SIGN_IN: RouteName.SIGN_IN,
}
