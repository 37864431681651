import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import MetadataService from '@services/metadata'

import { catchAxiosError } from '@utils/utils'

import { IMetadataState } from './types'

const initialState: IMetadataState = {
  locales: {},
  timeZones: {}
}

export const getLocales = createAsyncThunk(
  'metadata/getLocales',
  async (_, { rejectWithValue }) => {
    try {
      const response = await MetadataService.getLocales()

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const getTimeZones = createAsyncThunk(
  'metadata/getTimeZones',
  async (_, { rejectWithValue }) => {
    try {
      const response = await MetadataService.getTimeZones()

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const metadataSlice = createSlice({
  initialState,
  name: 'metadata',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocales.fulfilled, (state, action) => {
        if (!action.payload) return

        state.locales = action.payload
      })
      .addCase(getTimeZones.fulfilled, (state, action) => {
        if (!action.payload) return

        state.timeZones = action.payload
      })
  }
})

const { reducer } = metadataSlice

export default reducer
