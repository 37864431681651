import React, { FC } from 'react';
import styles from '../EnvelopeItem.module.scss'

import { useAllowedActions } from '@hooks/envelopes/useAllowedActions';

import { useTranslation } from 'react-i18next';

import {
  ComboButton,
  ILibDropdownItem,
  ILibHorizontalPosition
} from '@infologistics/frontend-libraries';

import {
  checkAllowedActions,
  getAllowedAction,
  getOptionsAllowedAction,
} from '@views/envelopes/utils';
import { actionButtonSettings } from '@views/envelopes/consts';

import { IEnvelopeActionsProps as IProps } from '@views/envelopes/types';

const EnvelopeActions: FC<IProps> = ({ allowedActions, oguid, subject }) => {
  const { t } = useTranslation(['envelopes'])
  const { handleClickAllowedActions } = useAllowedActions()

  const checkedAllowedActions = checkAllowedActions(allowedActions)
  const allowedActionValue = getAllowedAction(checkedAllowedActions)
  const { title, theme } = actionButtonSettings[allowedActionValue]

  const dropdownItems =
    getOptionsAllowedAction(false, checkedAllowedActions)
      .map((item) => {
        const { translation = '' } = item

        return {
          ...item,
          translation: t(translation)
        }
      })

  const handleSelectDropDown = (option: ILibDropdownItem): void =>
    handleClickAllowedActions({ option, oguid, subject })

  const handleClickButton = (): void => {
    const option = { name: allowedActionValue }
    handleClickAllowedActions({ option, oguid })
  }

  return (
    <td>
      <ComboButton
        selectedTitle={t(title)}
        buttonTheme={theme}
        containerClasses={styles.actionButton}
        dropdownItems={dropdownItems}
        itemClasses='py-1'
        size='small'
        horizontalPosition={ILibHorizontalPosition.RIGHT}
        onSelect={handleSelectDropDown}
        allowDropdownFlip
        correctViewport={{ bottom: 48 }}
        onClick={handleClickButton}
      />
    </td>
  )
}

export default EnvelopeActions
