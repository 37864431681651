import { EnvelopeAllowedActionsButton, EnvelopeFlowState, FlowStageState, TasksStatus } from '@const/consts';
import { IEnvelopeSettingsElement } from '@views/envelopes/types';

export const flowStateSettings: IEnvelopeSettingsElement = {
  [EnvelopeFlowState.IN_PROGRESS]: {
    title: 'envelopes:status:inProgress',
    theme: 'primary',
    icon: 'IconInProgress'
  },
  [EnvelopeFlowState.SOLVED]:  {
    title: 'envelopes:status:solved',
    theme: 'success',
    icon: 'IconSuccess'
  },
  [EnvelopeFlowState.DECLINED]: {
    title:  'envelopes:status:declined',
    theme: 'danger',
    icon: 'IconProhibited'
  },
  [EnvelopeFlowState.STOPPED]: {
    title:  'envelopes:status:stopped',
    theme: 'default',
    icon: 'IconStateStop'
  },
  [EnvelopeFlowState.EXPIRED]: {
    title:  'envelopes:status:expired',
    theme: 'warning',
    icon: 'IconWarning'
  }
}

export const actionButtonSettings: IEnvelopeSettingsElement = {
  [EnvelopeAllowedActionsButton.SIGN]: {
    title: 'envelopes:actions:sign',
    theme: 'primary'
  },
  [EnvelopeAllowedActionsButton.APPROVE]: {
    title: 'envelopes:actions:approve',
    theme: 'info'
  },
  [EnvelopeAllowedActionsButton.VIEW]: {
    title: 'envelopes:actions:view',
    theme: 'default'
  }
}

export const flowStageStateSettings: IEnvelopeSettingsElement = {
  [FlowStageState.WAITING]: {
    title: 'envelopes:stageState:waiting',
  },
  [FlowStageState.IN_PROGRESS]: {
    title: 'envelopes:status:inProgress',
  },
  [FlowStageState.COMPLETED]: {
    title: 'envelopes:stageState:completed',
  },
  [FlowStageState.CANCELED]: {
    title: 'envelopes:stageState:canceled',
  }
}

export const taskStatusSettings: IEnvelopeSettingsElement = {
  [TasksStatus.APPROVAL]: {
    title: 'envelopes:taskStatus:approval',
    icon: 'IconStateApproving'
  },
  [TasksStatus.ACQUAINTANCE]: {
    title: 'envelopes:taskStatus:acquaintance',
    icon: 'IconStateReceiving'
  },
  [TasksStatus.SIGNING]: {
    title: 'envelopes:taskStatus:signing',
    icon: 'IconStateSigning'
  }
}
