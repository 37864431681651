import { isEmpty } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'

import TokensService from '@services/tokensService'

import { Loader } from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { setLoaded } from '@store/modules/startup'
import { getLocales, getTimeZones } from '@store/modules/metadata'
import { getCurrentUser, getSettingsUser } from '@store/modules/user'
import { setLanguage } from '@store/modules/utils'
import { catchThunkError, displayErrorNotification } from '@utils/utils'

import { useUpdateLanguage } from '@hooks/useUpdateLanguage';
import { IStartupProps as IProps } from './types'

const Startup: FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const { updateLanguage } = useUpdateLanguage()

  if (process.env.REACT_APP_API_ENV === 'development') {
    window.setLanguage = (language: string): Promise<void> => (
      i18n.changeLanguage(language)
        .then(() => {
          dispatch(setLanguage(language))
        })
    )
  }

  const { isLoaded } = useAppSelector((state) => state.startup)
  const {
    isAuthenticated,
    profile: {
      email: userEmail,
      oguid: userOguid
    }
  } = useAppSelector((state) => state.user)
  const { language } = useAppSelector((state) => state.utils)
  const { locales, timeZones } = useAppSelector(state => state.metadata)

  const authUser = async (): Promise<void> => {
    const { accessToken, refreshToken } = TokensService.getTokensToStartup()

    // If there are valid tokens, then user authorization
    if (accessToken ?? refreshToken) {
      try {
        // If the access token is missing for some reason (expired),
        // then update it based on a valid refresh token
        if (refreshToken && !accessToken) {
          const isGenerateNewRefresh = TokensService.isGenerateNewRefresh(refreshToken)

          await TokensService.refresh(refreshToken, isGenerateNewRefresh)
        }

        const profile = await dispatch(getCurrentUser()).unwrap()
        const settings = await dispatch(getSettingsUser()).unwrap()

        Sentry.configureScope((scope) => {
          scope.setUser({
            email: userEmail ?? '',
            id: userOguid
          })
        })

        updateLanguage(profile)

        const asideWidth = settings?.asideWidth

        const getAsideWidth = (): string =>
          !asideWidth || (+asideWidth?.replace('rem', '') < 15) ?
            '15rem' : asideWidth

        document.documentElement.style
          .setProperty('--aside-width', getAsideWidth())

      } catch (data) {
        catchThunkError(data)
      }
    }

    dispatch(setLoaded())
  }

  useEffect(() => {
    TokensService.setTab()

    TokensService.clearBeforeUnloadTab()
  })

  useEffect(() => {
    !isLoaded && authUser().catch(displayErrorNotification)
  }, [isAuthenticated, isLoaded])

  useEffect(() => {
    if (language || (isEmpty(locales) || isEmpty(timeZones))) {
      Promise.all([
        dispatch(getLocales()).unwrap(),
        dispatch(getTimeZones()).unwrap()
      ])
        .catch(catchThunkError)
    }
  }, [language, isAuthenticated])


  return isLoaded ? children : <Loader loading />
}

export default Startup
