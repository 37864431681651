import React, { FC } from 'react';
import styles from '../EnvelopeItem.module.scss';
import cn from 'classnames';

import { EnvelopeAllovedActions, EnvelopeDirection } from '@const/consts';

import { IconArchiveBoxIn, IconCheck, IconInfo, IconSend } from '@infologistics/frontend-libraries';

import { IEnvelopIconsProps as IProps } from '@views/envelopes/types';
import { useAppSelector } from '@hooks/redux';

const EnvelopeIcons: FC<IProps> = ({ directions, allowedActions }) => {
  const { direction } = useAppSelector(state => state.envelopes.envelopes)

  const isDirectionIcon = directions && directions.length === 1
  const isDirectionIconIncoming = directions && directions[0] === EnvelopeDirection.INCOMING
  const isAllowedActionsSignOrApprove =
    allowedActions.includes(EnvelopeAllovedActions.SIGN) ||
    allowedActions.includes(EnvelopeAllovedActions.APPROVE)

  return (
    <td>
      <div className='d-flex'>
        { direction === null &&
          <span className={cn('d-flex justify-content-center', styles.directions)}>
            {
              isDirectionIcon && (isDirectionIconIncoming ? <IconArchiveBoxIn/> : <IconSend/>)
            }
          </span>
        }
        <span className={cn('d-flex justify-content-center', styles.flowState)}>
          {
            isAllowedActionsSignOrApprove ?
              <IconInfo color='primary-500' /> : <IconCheck />
          }
        </span>
      </div>
    </td>
  );
}

export default EnvelopeIcons
