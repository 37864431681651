import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux'

import store from '@store/configureStore'

import { LayoutRouter } from '@common/Layout'
import { Loader } from '@infologistics/frontend-libraries'
import Startup from '@views/startup'
import ButtonInstance from '@common/ButtonInstance'

import { isDevelopment } from '@const/consts'

import '@infologistics/frontend-libraries/dist/styles/styles.css'
import './App.css';

const App: FC = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loading />}>
        <Startup>
          <LayoutRouter />
        </Startup>

        { isDevelopment && <ButtonInstance />}
      </Suspense>
    </Provider>
  )
}

export default App;
