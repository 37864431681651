export default {
  auth: {
    tokenRefresh: 'tokenRefresh',
    signIn: 'signin',
    signUp: 'signup',
    passwordReset: 'passwordReset',
    passwordResetConfirm: 'passwordResetConfirm',
  },
  shelf: {
    preview: 'shelf/file/{{fileOguid}}/page/{{page}}',
    upload: 'shelf',
  },
  users: {
    current: 'user',
    generateAPIKey: 'generateAPIKey',
    settings: 'user/settings'
  },
  envelopes: {
    list: 'envelopes',
    envelope: 'boxes/{{ boxOguid }}/envelopes/{{ envelopeOguid }}',
    generateSignLink: 'envelopes/generateSignLink',
    flowStop: 'envelopes/{{ envelopeOguid }}/flowStop',
    todos: 'envelopes/{{ envelopeOguid }}/todos'
  },
  metadata: {
    locales: 'locales',
    timeZones: 'timeZones'
  },
  verify: {
    confirm: 'verify/phoneCheckConfirm',
    init: 'verify/phoneCheckInit'
  },
  shared: {
    getDocumentFile: '/shared/{{ sharedLinkOguid }}/envelope/file/{{ fileIndex }}/type/{{ fileType }}',
    getDocumentReview: '/shared/{{ sharedLinkOguid }}/envelope/file/{{ fileIndex }}/page/{{ page }}',
    generateShare: '/envelopes/{{ envelopeOguid }}/generateShare'
  },
  boxes: {
    webHookIntegration: '/boxes/{{ boxOguid }}/webHookIntegration'
  }
}
