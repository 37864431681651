import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAppSelector } from '@hooks/redux'
import { getRoutesList } from '@utils/utils'

import { Page404 } from '@views/misc'

import { IRenderRoutesProps as IProps } from './types'

const RenderRoutes: FC<IProps> = ({ routes }) => {
  const routeslist = getRoutesList(routes)

  const { isError404 } = useAppSelector((state) => state.utils)

  return (
    <Routes>
      {!isError404 && routeslist}
      <Route path='*' element={<Page404 />}/>
    </Routes>
  )
}

export default RenderRoutes
