import React, { FC } from 'react'
import HeaderWrapper from '../HeaderWrapper'
import Menu from '../Menu'

import { LayoutWrapper } from '@common/Layout'
import { PrivateRouteWrapper } from '@common/Routing'

import { useAppSelector } from '@hooks/redux'

const AppLayout: FC = () => {
  const { isHeaderVisible, isMenuVisible } = useAppSelector((state) => state.utils)

  return (
    <>
      {isHeaderVisible && <HeaderWrapper/>}
      {isMenuVisible && <Menu/>}
      <PrivateRouteWrapper>
        <LayoutWrapper />
      </PrivateRouteWrapper>
    </>
  )
}

export default AppLayout
