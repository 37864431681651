import AbstractHttpService from '@services/abstractHttpService';

import urls from '@const/urls';
import { AxiosResponse } from 'axios';
import { ICurrentWebHook, IWebHook } from '@store/modules/boxes/types';


class BoxesService extends AbstractHttpService {
  private readonly url = urls.boxes

  async getWebHook (boxOguid: string): Promise<AxiosResponse<ICurrentWebHook>> {
    const url = this.url.webHookIntegration.replace('{{ boxOguid }}', boxOguid)

    return await this._http.get(url, {})
  }

  async updateWebHook (boxOguid: string, webHook: IWebHook): Promise<AxiosResponse> {
    const url = this.url.webHookIntegration.replace('{{ boxOguid }}', boxOguid)

    return await this._http.put(url, webHook, {})
  }
}

export default new BoxesService
