import { Nullable } from '@app/types';
import { Checkbox } from '@hooks/envelopes/types';

import {
  TasksStatus,
  EnvelopeAllovedActions,
  EnvelopeDirection,
  EnvelopeFlowState,
  RecipientAccessAuthorizedBy,
  FlowStageState,
  DraftEnvelopePrepositionedMarkFont, DraftEnvelopePrepositionedMarkType, DraftEnvelopePrepositionedMarkFontType, EnvelopeFlowResult
} from '@const/consts'

export interface IEnvelope {
  oguid: string
  sender: IEnvelopeSender
  directions: Nullable<EnvelopeDirection[]>
  lastActionDateTime: string
  flowStartDateTime: string
  flowCompletedDateTime: string
  subject: string
  taskTotalCount: number
  taskCompletedCount: number
  allowedActions: EnvelopeAllovedActions[]
  fields: IEnvelopeFields
  flowState: EnvelopeFlowState
  recipients: IRecipients[]
  sharedLinkOguid: string
}

export interface IRecipients {
  email: string
  guestEmail: Nullable<string>
  fullName: string
}

export interface IEnvelopePost {
  senderBoxOguid: string
  fields: IEnvelopeFields
  subject: string
  comment: string
  externalId: string
  files: string[]
  expirationDateTime: string
  flow: IEnvelopePostFlow[]
}

export interface ICurrentEnvelope extends IEnvelope {
  comment: Nullable<string>
  externalId: Nullable<string>
  unreadTodosCount: number
  files: Nullable<IEnvelopeFiles[]>
  currentUserTaskOguid: Nullable<string>
  expirationDateTime: string
  flow: ICurrentEnvelopeFlow[]
  sharedLinkOguid: string
}

export interface IEnvelopeGenerateLink {
  fields: IEnvelopeFields
  subject: string
  files: IEnvelopeFiles[]
  recipient: IEnveloperPostRecipient
  redirectTo: Nullable<string>
}

export interface IEnvelopeStopAgreementProcess {
  dateTime: string
  correlationId: string
  error: string
  message: string
  debugMessage: string
}

export interface IEnvelopeDiscussionObjectApproval {
  oguid: string
  readDateTime: string
  author: IEnvelopeUser
  recipient: IEnvelopeUser
  comment: string
  description: string
  todos: Array<object> // TODO: [ { } ], Array of objects or null (todo) Child TODO
  startedDateTime: string
  completedDateTime: string
}

export interface IEnvelopeSender {
  boxOguid: string
  boxEmail: string
  user: IEnvelopeUser
}

export interface IEnvelopeUser {
  oguid: string
  position: string
  email: string
  fullName: string
}

export interface ICurrentEnvelopeFlow {
  oguid: Nullable<string>
  state: Nullable<FlowStageState>
  tasks: IEnvelopeTasks[]
}

export interface IEnvelopeTasks {
  oguid: string
  type: TasksStatus
  readDateTime: Nullable<string>
  state: Nullable<FlowStageState>
  result: Nullable<EnvelopeFlowResult>
  recipient: ICurrentEnvelopeRecipient
  startedDateTime: string
  completedDateTime: Nullable<string>
  completedSigner: Nullable<IEnveloperCompletedSigner>
  completedEvidences: Nullable<IEnveloperCompletedEvidences>
}

export interface ICurrentEnvelopeRecipient {
  boxOguid: string
  email: string
  isHostMode: boolean
  guestEmail: Nullable<string>
  declaredSigner: Nullable<IEnveloperSigner>
  isAllowElectronicSignature: boolean
  isRequireSelfie: boolean
  isRequireSms: boolean
}

export interface IEnvelopePostFlow {
  recipients: IEnveloperPostRecipient[]
}

export interface IEnveloperPostRecipient {
  type: TasksStatus
  email: string
  isHostMode: boolean
  guestEmail: string
  signer: IEnveloperSigner
  isAllowElectronicSignature: boolean
  isRequireSelfieToSignElectronically: boolean
  isRequireSmsToSignElectronically: boolean
  prepositionedMarks: IEnveloperPrepositionedMark[]
}

export interface IEnveloperSigner {
  fullName: string
  initials: Nullable<string>
  position: Nullable<string>
  phone: Nullable<number>
}

export interface IEnveloperCompletedSigner {
  oguid: string
  position: string
  email: string
  fullName: string
  initials: string
  phone: string
}

export interface IEnveloperCompletedEvidences {
  isVerifiedBySms: boolean
  phone: number
  isSelfieProvided: boolean
  selfieFileOguid: string
  geolocation: string
  userAgent: string
  recipientAccessAuthorizedBy: RecipientAccessAuthorizedBy
}

export interface IEnveloperPrepositionedMark {
  fileIndex: number
  key: string
  pageNumber: number
  topLeftX: number
  topLeftY: number
  height: number
  width: number
  type: string
  isReadOnly: boolean
  isRequired: boolean
  valueList: string[]
  value: string
  fontSize: number
  fontType: string
  font: string
  color: string
  scale: number
  tooltip: string
}

export interface IEnvelopeFiles {
  index: number
  filename: string
  originalProperties: {
    size: number
    mimeType: string
  }
  signedProperties: Nullable<{
    size: number
    mimeType: string
  }>
  pages: Nullable<string>
}

export interface IEnvelopeFields {
  [key: string]: boolean | number | string | null
}

export interface IEnvelopesListParams {
  direction: Nullable<EnvelopeDirection | undefined>
  boxOguid?: string
  page?: Nullable<string>
  perPage?: Nullable<string>
}

export interface IEnvelopeParams {
  oguid: string
  boxOguid: string
}

export interface IGetPreviewParams {
  fileUuid: string
  requestUuid?: string
}

export interface IPreviewDimensions {
  width: number,
  height: number
}

export interface IPreviewData {
  dimensions: IPreviewDimensions
  fileCode: string
  mimeType: string
  orientation: string
}

export interface IGetPreview extends IPreviewData {
  totalPages: number
}

export interface IEnvelopeFilePreview {
  data: IPreviewData[]
  fileName: string
  isAvailable: boolean
  total: number
}

export interface IDraftEnvelopeRecipientSigner {
  fullName: string
  initials: Nullable<string>
  position: Nullable<string>
  phone: Nullable<string>
}

export enum DraftEnvelopeRecipientType {
  ACQUAINTANCE = 'ACQUAINTANCE',
  APPROVAL = 'APPROVAL',
  SIGNING = 'SIGNING'
}

export enum DraftEnvelopeRecipientSignerType {
  PART = 'PART',
  WITNESS = 'WITNESS'
}

export interface IDraftEnvelopeRecipientSigningParams {
  isAllowElectronicSignature: boolean
  signerType: DraftEnvelopeRecipientSignerType
}

export interface IDraftEnvelopeRecipientData {
  boxEmail: string
  guestEmail: Nullable<string>
  isHostMode: boolean
  isRequireSelfie: boolean
  isRequireSms: boolean
  prepositionedMarks: Nullable<IDraftEnvelopePrepositionedMarkData[]>
  signer: IDraftEnvelopeRecipientSigner
  signingParams: IDraftEnvelopeRecipientSigningParams
  type: DraftEnvelopeRecipientType
}

export interface IDraftEnvelopeRecipient extends IDraftEnvelopeRecipientData {
  color: string,
  groupNum: number
}

export interface IDraftEnvelopePrepositionedMarkData {
  color: Nullable<string>
  fileIndex: number
  font: Nullable<DraftEnvelopePrepositionedMarkFont>
  fontSize: Nullable<number>
  fontType: Nullable<DraftEnvelopePrepositionedMarkFontType>
  height: Nullable<number>
  isReadOnly: Nullable<boolean>
  isRequired: Nullable<boolean>
  pageNumber: number
  scale: Nullable<number>
  tooltip: Nullable<string>
  topLeftX: number
  topLeftY: number
  type: DraftEnvelopePrepositionedMarkType,
  value: Nullable<string>
  valueList: Nullable<string[]>
  width: Nullable<number>
}

export interface IDraftEnvelopePrepositionedMark extends IDraftEnvelopePrepositionedMarkData {
  recipient: string
}

export interface ISetDraftEnvelopePrepositionedMark {
  mark: Partial<IDraftEnvelopePrepositionedMark>
  uuid: string
}

export interface IDraftEnvelopeMessage {
  comment: Nullable<string>
  expirationDateTime: Nullable<Date>
  externalId: Nullable<string>
  subject: string
}

export interface IDraftEnvelopeField {
  key: string,
  value: Nullable<string>
}

export interface IDraftEnvelope {
  fields: Record<string, IDraftEnvelopeField>
  fieldsIdList: string[]
  files: string[]
  message: IDraftEnvelopeMessage
  preview: {
    [uuid: string]: IEnvelopeFilePreview
  }
  recipients: Record<string, IDraftEnvelopeRecipient>
  recipientsColors: string[]
  recipientsIdList: string[]
  prepositionedMarks: Record<string, IDraftEnvelopePrepositionedMark>
  prepositionedMarksIdList: string[]
}

export interface IGetPreviewThunkArg {
  fileOguid: string,
  page?: number
}

export interface IDraftEnvelopeFlowItem {
  recipients: Array<IDraftEnvelopeRecipientData>
}

export interface IDraftEnvelopeData {
  comment: Nullable<string>
  expirationDateTime: Nullable<string>
  externalId: Nullable<string>
  fields: Nullable<Record<string, string>>
  files: string[]
  flow: Array<IDraftEnvelopeFlowItem>
  senderBoxOguid: Nullable<string>
  subject: string
}

export interface IEnvelopesState {
  envelopes: {
    data: IEnvelope[],
    direction: Nullable<EnvelopeDirection | undefined>
  },
  checkedEnvelopes: Checkbox,
  currentEnvelope: Nullable<ICurrentEnvelope>
  draftEnvelope: IDraftEnvelope
}
