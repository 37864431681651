import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'

import loaderReducer from './modules/loader'
import metadataReducer from './modules/metadata'
import navigationReducer from './modules/navigation'
import startupReducer from './modules/startup'
import userReducer from './modules/user'
import utilsReducer from './modules/utils'
import envelopesReducer from './modules/envelopes'
import paginationReducer from './modules/pagination'
import modalReducer from './modules/modal'
import boxesReducer from './modules/boxes'

import { AppState } from '@store/types'

export const appReducer = combineReducers({
  loader: loaderReducer,
  metadata: metadataReducer,
  navigation: navigationReducer,
  startup: startupReducer,
  user: userReducer,
  utils: utilsReducer,
  envelopes: envelopesReducer,
  pagination: paginationReducer,
  modal: modalReducer,
  boxes: boxesReducer
})

const rootReducer: Reducer = (state: AppState, action) => {
  if (action.type === 'user/signOut') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = configureStore<AppState>({
  devTools: { trace: true },
  reducer: rootReducer
})

export default store


