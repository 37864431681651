import { useEffect } from 'react';
import { useAppSelector } from '@hooks/redux';

import { useGetParams } from '@hooks/envelopes/useGetParams';
import { useLoadEnvelopes } from '@hooks/envelopes/useLoadEnvelopes';

export const useGetEnvelopes = () => {
  const {
    envelopes: { data }
  } = useAppSelector(state => state.envelopes)

  const { loadEnvelopes } = useLoadEnvelopes()

  const { collectedParams, setCollectedParams } = useGetParams()

  useEffect(() => {
    if (collectedParams) {
      loadEnvelopes()
      setCollectedParams(false)
    }
  }, [ collectedParams ])

  return {
    data,
    loadEnvelopes
  }
}
