import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Pagination } from '@infologistics/frontend-libraries';

import { TOptions } from 'i18next';
import { IPaginationTexts } from '@infologistics/frontend-libraries/dist/components/Pagination/types';
import { IPaginationProps as IProps } from '@common/PaginationWrapper/types';

const PaginationWrapper: FC<IProps> = ({ changePage, pagination, itemsInPage, isDisabled = false }) => {
  const { t } = useTranslation(['pagination'])
  const location = useLocation()

  const texts: IPaginationTexts = {
    by: t('pagination:by'),
    entries: t('pagination:entries'),
    firstPage: t('pagination:firstPage'),
    lastPage: t('pagination:lastPage'),
    next: t('pagination:next'),
    previous: t('pagination:previous'),
    showRows: (options: TOptions) => t('pagination:showRows', options),
    showTotal: (options: TOptions) => t('pagination:showTotal', options)
  }

  return (
    <Pagination
      changePage={changePage}
      pagination={pagination}
      itemsInPage={itemsInPage}
      texts={texts}
      location={location}
      isDisabled={isDisabled}
      isUpperLastPage
    />
  );
}

export default PaginationWrapper
