import cn from 'classnames'
import React, { FC, KeyboardEvent, MouseEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { MouseEventType } from '@const/consts'
import { useAppSelector } from '@hooks/redux'
import { createUrl, transformPathToString } from '@utils/utils'

import { Ellipsis, LinkContent } from '@infologistics/frontend-libraries'

import styles from './MenuItem.module.scss'

import { INavItemContentData } from '../../types'
import { IMenuItemProps as IProps,  } from './types'

const MenuItem: FC<IProps> = ({ item }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { activeMenu } = useAppSelector((state) => state.navigation)
  const [currentRoute, setCurrentRoute] = useState('')
  const { t } = useTranslation(['envelopes'])

  const {
    contentData,
    icon,
    iconProps,
    iconCollapse,
    iconCollapseProps,
    items,
    title,
    route
  } = item

  const setRoute = () => {
    setCurrentRoute(items?.[0]?.route ?? route)
  }

  useEffect(setRoute)

  const newTo = transformPathToString(route)

  const activeMenuSource = activeMenu || location.pathname

  const handleOpenMenuList = (evt: MouseEvent | KeyboardEvent): void => {
    const menuItemHasChildren = !!item.items

    const url = createUrl(currentRoute)

    if (menuItemHasChildren || evt.type === MouseEventType.AUXCLICK) {
      evt.preventDefault()

      navigate(url)
    }
  }

  const getLinkContent = (data?: INavItemContentData): ReactElement | undefined => {
    if (!data) return undefined

    const { content, heading } = data

    return (
      <div className='d-flex flex-column font-xs'>
      <span className={cn('d-inline-block fw-700', styles.content_heading)}>
        <Ellipsis>{heading}</Ellipsis>
      </span>
      <span className='fw-400 text-muted-700'>{content}</span>
      </div>
    )
  }

  return (
    <NavLink
      className={() => activeMenuSource.includes(newTo) ? 'active' : ''}
      onAuxClick={handleOpenMenuList}
      onClick={handleOpenMenuList}
      title={t(title)}
      to={route}
    >
      <LinkContent
        content={getLinkContent(contentData)}
        iconBefore={icon}
        iconBeforeProps={{ ...iconProps, classes: 'mr-2' }}
        iconCollapse={iconCollapse}
        iconCollapseProps={{ ...iconCollapseProps, classes: 'ml-auto' }}
        title={t(title)}
      />
    </NavLink>
  )
}

export default MenuItem


