import AbstractHttpService from '@services/abstractHttpService';

import urls from '@const/urls';

import {AxiosResponse} from 'axios';
import {
  IGetDocumentFile,
  IGetDocumentFileArgs,
  IGetDocumentPreviewArgs
} from '@services/types';

class SharedService extends AbstractHttpService {
  private readonly url = urls.shared

  async getDocumentFile ({ sharedLinkOguid, fileIndex, fileType }: IGetDocumentFileArgs): Promise<AxiosResponse<IGetDocumentFile>> {
    const url = this.url.getDocumentFile
      .replace('{{ sharedLinkOguid }}', sharedLinkOguid)
      .replace('{{ fileIndex }}', fileIndex)
      .replace('{{ fileType }}', fileType)

    return await this._http.get(url)
  }

  async getSharedOguid (envelopeOguid: string): Promise<AxiosResponse<string>> {
    const url = this.url.generateShare
      .replace('{{ envelopeOguid }}', envelopeOguid)

    return await this._http.post(url, {})
  }

  async getPreviewFile ({ sharedLinkOguid, fileIndex, page }: IGetDocumentPreviewArgs): Promise<AxiosResponse<string>> {
    const url = this.url.getDocumentReview
      .replace('{{ sharedLinkOguid }}', sharedLinkOguid)
      .replace('{{ fileIndex }}', fileIndex)
      .replace('{{ page }}', page)

    return await this._http.get(url, {responseType: 'blob'})
  }
}

export default new SharedService()
