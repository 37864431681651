import { lazy } from 'react'

import { Path } from '@const/paths'
import { IRoute } from '@common/Routing/types'
import { getLazyComponent } from '@utils/utils'

const Register = lazy(() => import('./components/Register'));
const Signin = lazy(() => import('./components/Signin'));
const PasswordReset = lazy(() => import('./components/PasswordReset'));
const PasswordResetConfirm = lazy(() => import('./components/PasswordResetConfirm'));

export const routes: IRoute[] = [
  {
    element: getLazyComponent(Register),
    name: 'Register',
    path: Path.REGISTER
  },
  {
    element: getLazyComponent(Signin),
    name: 'Signin',
    path: Path.SIGN_IN
  },
  {
    element: getLazyComponent(PasswordReset),
    name: 'PasswordReset',
    path: Path.PASSWORD_RESET
  },
  {
    element: getLazyComponent(PasswordResetConfirm),
    name: 'PasswordResetConfirm',
    path: Path.PASSWORD_RESET_CONFIRM
  }
]
