import React, { FC } from 'react';

import { IEnvelopeCheckboxProps as IProps } from '@views/envelopes/types';

import { Checkbox , getCheckboxCheckedValue } from '@infologistics/frontend-libraries';

const EnvelopeCheckbox: FC<IProps> = ({ oguid, checkbox, selectedCheckbox }) => {
  return (
    <td>
      <Checkbox
        checked={getCheckboxCheckedValue(checkbox)}
        name='checkDocument'
        onChange={(e) => selectedCheckbox(e.target.value)}
        value={oguid}
      />
    </td>
  )
}

export default EnvelopeCheckbox
