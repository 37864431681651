import React, { ComponentType, FC, LazyExoticComponent, ReactNode, Suspense } from 'react'

import { Route, To } from 'react-router-dom'
import { AxiosError, AxiosResponseHeaders } from 'axios'
import { FormikErrors, FormikTouched } from 'formik'
import { get, has, omit } from 'lodash'
import { DateTime } from 'luxon'
import i18n from '@app/i18n'

import {
  BrowserStorage,
  DateFormat,
  DraftEnvelopePrepositionedMarkType,
  Instance,
  Languages,
  LocalStorageKeysForSaveBetweenSessions,
  Space,
  BASE_URL,
  lookupSessionStorage
} from '@const/consts'

import { paginationInitialState } from '@store/modules/pagination/consts'

import {
  ILibOption,
  ILibPagination,
  ILibSelectValueType,
  Loader,
  Nullable,
  showBasicNotification,
  showConfirmNotification,
  showErrorNotification,
  showSuccessNotification
} from '@infologistics/frontend-libraries'

import { IRoute } from '@common/Routing/types'
import {
  IBasicNotificationOptions,
  IConfirmNotificationOptions,
  IName,
  ISuccessNotificationOptions,
  ITo
} from './types'
import { SelectChangeFunction } from '@app/types'
import {
  IDraftEnvelope,
  IDraftEnvelopeData,
  IDraftEnvelopePrepositionedMarkData,
  IDraftEnvelopeRecipientData,
  IPreviewDimensions
} from '@store/modules/envelopes/types'
import store from '@store/configureStore'
import { setLanguage } from '@store/modules/utils'

export const displaySuccessNotification = (options: ISuccessNotificationOptions): void => {
  const { content, title } = options
  const { language, getResource } = i18n

  showSuccessNotification({
    content: content ?? String(getResource(language, 'notification', 'successContent')),
    title: title ?? String(getResource(language, 'notification', 'successTitle'))
  })
}

export const displayBasicNotification = (options: IBasicNotificationOptions): void => {
  const { content, title, type } = options
  const { language, getResource } = i18n

  showBasicNotification({
    content: content || getResource(language, 'notification', 'successContent'),
    title: title || getResource(language, 'notification', 'successTitle'),
    type
  })
}

export const displayErrorNotification = ({ response }: AxiosError<any>): void => {
  const { language, getResource } = i18n

  const errorMessage = String(getResource(language, 'notification', 'errorContent'))

  showErrorNotification({
    content: response?.data?.message || errorMessage,
    title: ''
  })
}

export const displayThunkErrorNotification = (data: Record<string, any> | undefined): void => {
  const { language, getResource } = i18n

  const errorMessage = String(getResource(language, 'notification', 'errorContent'))

  showErrorNotification({
    content: data?.message || errorMessage,
    title: ''
  })
}

export const displayConfirmNotification = (options: IConfirmNotificationOptions): void => {
  const { cancelText, submitText, content, contentNotification, title, onSubmit, theme } = options
  const { language, getResource } = i18n

  showConfirmNotification({
    cancelText: cancelText ?? getResource(language, 'common', 'no'),
    content: content ?? getResource(language, 'notification', 'confirmContent'),
    contentNotification,
    onSubmit,
    submitText: submitText ?? getResource(language, 'common', 'yes'),
    title: title ?? getResource(language, 'notification', 'confirmTitle'),
    theme: theme ?? 'warning'
  })
}

export const isAxiosError = (candidate: any): candidate is AxiosError => candidate.isAxiosError === true

export const isObject = (candidate: any): candidate is Record<string, any> => (
  candidate !== null && typeof candidate === 'object'
)

export const getRoutesList = (routes: IRoute[]): ReactNode[] =>
  routes.map((route: IRoute) => {
    const { name, path, routeAdditionalProp } = route

    return <Route key={name} path={path} element={<route.element routeAdditionalProp={routeAdditionalProp} />} />
  })

export const createUrl = (...urlData: Array<string | number>): string => (
  urlData.reduce((acc: string, currentValue: string | number) => {
    if (!currentValue) return acc

    const newCurrentValue =
      typeof currentValue !== 'number' && !currentValue.includes('/') && acc.slice(-1) !== '/'
        ? `/${currentValue}`
        : currentValue
    return `${acc}${newCurrentValue}`
  }, '')
)

export const transformPathToString = (to: To): string => {
  const newTo = ''

  if (typeof to === 'string') return newTo + to
  if (typeof to === 'object' && to.pathname) return to.pathname

  return newTo
}

export const getLazyComponent = (ComponentLazy: LazyExoticComponent<ComponentType<any>>): FC => (props) => (
  <Suspense fallback={<Loader loading />}>
    <ComponentLazy {...props} />
  </Suspense>
)

export const hasError = (name: string, errors?: FormikErrors<any>, touched?: FormikTouched<any>): boolean =>
  has(errors, name) && has(touched, name)

export const getErrorText = (name: string, errors?: FormikErrors<any>): string =>
  has(errors, name) ? String(get(errors, name)) : ''

export const clearLocalStorage = (): void => {
  const keysToClear = Object.keys(localStorage).filter(
    (key) => !(LocalStorageKeysForSaveBetweenSessions).includes(key)
  )

  keysToClear.forEach((key) => {
    localStorage.removeItem(key)
  })
}

export const catchAxiosError = (err: unknown, rejectWithValue: (value: unknown) => void): void => {
  if (!isAxiosError(err)) return

  return rejectWithValue(err.response?.data)
}

export const catchThunkError = (data: unknown): void => {
  if (!isObject(data)) return

  displayThunkErrorNotification(data)
}

export const getLanguageName = (locale: string): string => {
  const localeName = locale.substring(0, 2)

  return Object.values(Languages).includes(localeName) ? localeName : Languages.EN
}

export const selectChangeCheck = (cb: any) => (option: ILibSelectValueType): SelectChangeFunction | undefined => (
  option && !Array.isArray(option)
    ? cb(option)
    : undefined
)

export const getDateFormat = (value: string, format: DateFormat): string =>
  DateTime.fromISO(value).toFormat(format)

export const getLocaleDateFormat = (value: string, locale: string): string =>
  DateTime.fromISO(value)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_SHORT)

export const getPagination = (headers: AxiosResponseHeaders): ILibPagination => {
  const itemsPerPage = headers['x-per-page']
  const nextPage = headers['x-next-page']
  const prevPage = headers['x-prev-page']
  const pageIndex = headers['x-page']
  const total = headers['x-total']

  if (
    !itemsPerPage && !nextPage && !prevPage && !pageIndex && !total
  ) return paginationInitialState

  return {
    itemsPerPage: itemsPerPage ? +itemsPerPage : 25,
    nextPage: nextPage ? +nextPage : null,
    prevPage: prevPage ? +prevPage : null,
    pageIndex: pageIndex ? +pageIndex : 1,
    total: total ? +total : null
  }
}

export const redirectBack = (to: ITo): void => {
  const { navigate, section } = to
  const isHistory = window.history.length > 2

  if (isHistory) return navigate(-1)

  const url = createUrl(section)
  return navigate(url)
}

export const convertBytesToKilobytes = (bytes: number): number =>
  +(bytes / 1000).toFixed(2)

export const getDraftEnvelopeData = (values: IDraftEnvelope, senderBoxOguid: string): IDraftEnvelopeData => {
  const {
    fields: fieldsValues,
    fieldsIdList,
    files,
    message: {
      comment,
      expirationDateTime: expirationDateTimeValue,
      externalId,
      subject
    },
    prepositionedMarks: prepositionedMarksValues,
    prepositionedMarksIdList,
    preview,
    recipients: recipientsValues,
    recipientsIdList
  } = values

  const expirationDateTime = expirationDateTimeValue
    ? DateTime.fromJSDate(expirationDateTimeValue).startOf('day').toISO({suppressMilliseconds: true})
    : null

  const sortedRecipients = {}

  recipientsIdList.forEach((id) => {
    const recipient = recipientsValues[id]
    const { groupNum, boxEmail, guestEmail } = recipient

    const {
      signer: {
        phone,
        ...restSigner
      },
      ...restRecipientData
    } = omit(recipient, ['color', 'groupNum'])
    const recipientData = {
      ...restRecipientData,
      boxEmail: boxEmail ? boxEmail.replace(/\s/g, '') : null,
      guestEmail: guestEmail ? guestEmail.replace(/\s/g, '') : null,
      signer: {
        ...restSigner,
        phone: phone ? +phone.replace(/\D/g, '') : null
      }
    }
    const prepositionedMarks = prepositionedMarksIdList
      .filter((markId) => prepositionedMarksValues[markId].recipient === id)
      .reduce((acc: IDraftEnvelopePrepositionedMarkData[], markId) => {
        const prepositionedMarkData = omit(prepositionedMarksValues[markId], ['recipient'])

        const { fileIndex, pageNumber, type, topLeftX, topLeftY } = prepositionedMarkData

        const { width, height } = preview[files[fileIndex]].data[pageNumber - 1].dimensions

        const newMarkData =
          type === DraftEnvelopePrepositionedMarkType.CHECKBOX ||
          type === DraftEnvelopePrepositionedMarkType.FULLNAME ||
          type === DraftEnvelopePrepositionedMarkType.INITIALS ||
          type === DraftEnvelopePrepositionedMarkType.POSITION ||
          type === DraftEnvelopePrepositionedMarkType.STRING
            ? {
              ...prepositionedMarkData,
              topLeftX: +((topLeftX / 100 * width + 4) * 100 / width).toFixed(4),
              topLeftY: +((topLeftY / 100 * height + 4) * 100 / height).toFixed(4),
            } : prepositionedMarkData

        return (
          [
            ...acc,
            newMarkData
          ]
        )
      }, [])

    recipientData.prepositionedMarks = prepositionedMarks

    sortedRecipients[groupNum] = Array.isArray(sortedRecipients[groupNum])
      ? [
        ...sortedRecipients[groupNum],
        recipientData
      ] : [
        recipientData
      ]
  })

  const flow = Object.values<IDraftEnvelopeRecipientData[]>(sortedRecipients).map((recipients) => (
    {
      recipients
    }
  ))

  const fields: Record<string, string> = fieldsIdList.reduce((acc, id) => {
    const {
      key,
      value
    } = fieldsValues[id]

    return {
      ...acc,
      [key]: value
    }
  }, {})

  return {
    comment,
    expirationDateTime,
    externalId,
    fields: fieldsIdList.length ? fields : null,
    files,
    flow,
    senderBoxOguid,
    subject
  }
}

export const getMetadataSelectOptions = (object: Record<string, string>): ILibOption[] => (
  Object.keys(object).map((value) => ({
    label: object[value],
    value
  }))
)

export const getFullname = (name: IName): string => {
  const { lastName, firstName, middleName, language } = name

  return language !== Languages.RU
    ? collectNameString(firstName, middleName, lastName)
    : collectNameString(lastName, firstName, middleName)
}

export const getInitials = (name: IName): string => {
  const { lastName, firstName, middleName, language } = name

  const firstNameInitial = firstName ? firstName[0].toUpperCase() + '.' : ''
  const middleNameInitial = middleName ? middleName[0].toUpperCase() + '.' : ''

  return language !== Languages.RU
    ? collectNameString(firstNameInitial, middleNameInitial, lastName)
    : collectNameString(lastName, firstNameInitial, middleNameInitial)
}

const collectNameString = (left?: Nullable<string>, center?: Nullable<string>, right?: Nullable<string>): string => {
  const space = Space.STANDARD

  return `${left ? left + space : ''}${center ? center + space : ''}${right ?? ''}`
}

export const getImageDimensions = async (fileCode: string, mimeType: string): Promise<IPreviewDimensions> => {
  const header = `data:${mimeType};base64`
  const src = `${header}, ${fileCode}`

  const img = new Image()
  img.src = src
  await img.decode()

  return {
    width: img.naturalWidth,
    height: img.naturalHeight
  }
}

export const getInstance = (passedInstance?: string): Instance => {
  const instance = passedInstance ?? getInstance(store.getState().utils.instance)

  if (instance === Instance.BR) return Instance.BR

  if (instance === Instance.RU) return Instance.RU

  if (instance === Instance.ZA) return Instance.ZA

  return Instance.GLOBAL
}

export const getLanguageFromInstance = (locale: string) => {
  const instance = getInstance(store.getState().utils.instance)

  const isRussiaInstance = instance === Instance.RU
  const isRussiaLocale = locale === Languages.RU

  if (!locale) {
    switch (instance) {
      case Instance.RU:
        return Languages.RU
      case Instance.BR:
        return Languages.PT
      default:
        return Languages.EN
    }
  }

  if (isRussiaLocale && isRussiaInstance) return Languages.RU

  if (!isRussiaLocale) return locale

  return Languages.EN
}

export const getPreferredLanguages = (instance: Instance | string, locale: string): string[] => {
  if (instance === Instance.RU || locale === Languages.RU) return ['ru', 'en', 'pt']

  return ['en', 'pt']
}

export const getNameFormat = (firstName: string, lastName: string, middleName: Nullable<string>, isRU?: boolean): string => (
  isRU
    ? `${lastName}${lastName && ' '}${firstName}${firstName && ' '}${middleName ?? ''}`
    : `${firstName}${firstName && ' '}${middleName ?? ''}${middleName ? ' ' : ''}${lastName}`
)

export const setFormLanguage = (): void => {
  const locale = localStorage.getItem(BrowserStorage.LANGUAGE) ?? getLanguageName(navigator.language)
  const language = getLanguageFromInstance(locale)

  if (i18n.language === language) return

  i18n
    .changeLanguage(language)
    .then(() => {
      store.dispatch(setLanguage(language))
    })
    .catch(displayErrorNotification)
}

export const getBaseUrl = (): string => sessionStorage.getItem(lookupSessionStorage) ?? BASE_URL
