import { AxiosRequestConfig, AxiosResponse } from 'axios';
import AbstractHttpService from '@services/abstractHttpService';

import urls from '@const/urls';
import { IEnvelope, ICurrentEnvelope, IEnvelopesListParams, IDraftEnvelopeData, IEnvelopeParams } from '@store/modules/envelopes/types'

class EnvelopesService extends AbstractHttpService {
  private readonly url = urls.envelopes

  async getEnvelopesList ({ direction, boxOguid, page, perPage }: IEnvelopesListParams): Promise<AxiosResponse<IEnvelope[]>> {
    const url = this.url.list

    const params = { direction }
    boxOguid && (params['boxOguid'] = boxOguid)
    page && (params['page'] = page)
    perPage && (params['perPage'] = perPage)

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }

  async sendEnvelope (data: IDraftEnvelopeData): Promise<AxiosResponse<string>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  async getEnvelope ({ oguid, boxOguid }: IEnvelopeParams): Promise<AxiosResponse<ICurrentEnvelope>> {
    const url = this.url.envelope.replace('{{ envelopeOguid }}', oguid).replace('{{ boxOguid }}', boxOguid)

    return await this._http.get(url)
  }

  async postEnvelopeFlowStop (oguid: string): Promise<AxiosResponse> {
    const url = this.url.flowStop.replace('{{ envelopeOguid }}', oguid)

    return await this._http.post(url, {})
  }
}

export default new EnvelopesService()
