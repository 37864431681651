import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { resetCheckedEnvelopes, setCheckedEnvelopes } from '@store/modules/envelopes';

import { Nullable } from '@infologistics/frontend-libraries';

export const useCheckboxes = () => {
  const [headCheckbox, setHeadCheckbox] = useState<Nullable<boolean>>(false)
  const {
    envelopes: { data },
    checkedEnvelopes
  } = useAppSelector(state => state.envelopes)
  const dispatch = useAppDispatch()

  const selectedCheckbox = (oguid: string) =>
    dispatch(setCheckedEnvelopes({ [oguid]: !checkedEnvelopes[oguid] }))

  const selectedCount = (): Nullable<boolean> => {
    const checkedLength =
      Object
        .values(checkedEnvelopes)
        .filter(Boolean).length

    if (!checkedLength || !data.length)
      return false

    if (checkedLength && checkedLength !== data.length)
      return null

    return true
  }

  useEffect(() => {
    if (!data.length) return

    dispatch(resetCheckedEnvelopes())

    const initialCheckboxState = {}

    data.map(envelope =>
      initialCheckboxState[envelope.oguid] = false)

    dispatch(setCheckedEnvelopes(initialCheckboxState))
  }, [data])

  useEffect(() => {
    const checkboxState = { ...checkedEnvelopes }
    const editCheckboxStatus = (status: boolean) => {
      Object.keys(checkedEnvelopes).map(oguid => checkboxState[oguid] = status)
      dispatch(setCheckedEnvelopes(checkboxState))
    }

    headCheckbox && !selectedCount() && editCheckboxStatus(true)
    !headCheckbox && selectedCount() && editCheckboxStatus(false)
  }, [headCheckbox])

  useEffect(() => {
    setHeadCheckbox(selectedCount())
  }, [checkedEnvelopes])

  return {
    headCheckbox,
    setHeadCheckbox,
    checkedEnvelopes,
    selectedCheckbox
  }
}
