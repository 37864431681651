import React, { FC } from 'react';
import PaginationWrapper from '@common/PaginationWrapper';
import { useAppSelector } from '@hooks/redux';
import { useLocation, useSearchParams } from 'react-router-dom';

const EnvelopesPagination: FC = () => {
  const { data } = useAppSelector(state => state.envelopes.envelopes)
  const pagination = useAppSelector(state => state.pagination)
  const [ , setSearchParams ] = useSearchParams()
  const { pathname } = useLocation()

  if (!pagination) return null

  const isDisabled =
    pagination.nextPage === null &&
    pagination.pageIndex === 1

  const handleChangePage = (pageIndex: number | string): void => {
    const params = {
      page: `${pageIndex}`,
    }

    const itemPerPage = localStorage.getItem(pathname)
    itemPerPage && (params['perPage'] = itemPerPage)

    setSearchParams(params)
  }

  return (
    <PaginationWrapper
      changePage={handleChangePage}
      pagination={pagination}
      itemsInPage={data.length}
      isDisabled={isDisabled}
    />
  );
}

export default EnvelopesPagination
