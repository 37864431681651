import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RouteName } from '@const/paths'

import { ActionButton, Button, Header} from '@infologistics/frontend-libraries'

import Logo from '../LogoWrapper'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { signOut } from '@store/modules/user'

const HeaderWrapper: FC = () => {
  const { profile } = useAppSelector((state) => state.user)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['auth'])

  const { firstName, lastName } = profile

  const handleSignOut = (): void => {
    dispatch(signOut(profile.oguid))
    navigate(RouteName.SIGN_IN)
  }

  const handleProfileButtonClick = () =>
    navigate(RouteName.PROFILE)

  return (
    <Header>
      <Logo/>
      <div className='d-flex align-items-center ml-auto mr-3'>
        <Button
          classes='mr-2'
          leftIcon='IconUser'
          size='extra-small'
          onClick={handleProfileButtonClick}
        >
          {`${firstName} ${lastName}`}
        </Button>
        <ActionButton
          externalClass='p-1'
          icon='IconSignOut'
          onClick={handleSignOut}
          title={t('auth:signOut')}
        />
      </div>
    </Header>
  )
}

export default HeaderWrapper
