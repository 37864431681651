import { ILibPagination } from '@infologistics/frontend-libraries';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paginationInitialState as initialState } from '@store/modules/pagination/consts';

export const paginationSlice = createSlice({
  initialState,
  name: 'pagination',
  reducers: {
    setPagination: (state, action: PayloadAction<ILibPagination>) =>
      ({ ...action.payload }),
    resetPagination: () => ({ ...initialState })
  }
})

const { actions, reducer } = paginationSlice

export const { setPagination, resetPagination } = actions

export default reducer
