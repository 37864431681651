import { AxiosRequestConfig, AxiosResponse } from 'axios'
import AbstractHttpService from '../abstractHttpService'

import { needResetParams } from '@const/consts'
import urls from '@const/urls'

import { ITokensFromAuth, ISigninData } from '@store/modules/user/types'
import { IRegisterData } from '@views/auth/components/Register/types'
import { IPasswordResetValues } from '@views/auth/components/PasswordReset/types'
import { IChangePasswordData } from '@views/auth/components/PasswordResetConfirm/types'

class AuthService extends AbstractHttpService {
  private readonly url = urls.auth

  async signIn (data: ISigninData): Promise<AxiosResponse<ITokensFromAuth>> {
    const url = this.url.signIn

    const params = needResetParams

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.post(url, data, config)
  }

  async signUp (data: IRegisterData): Promise<AxiosResponse> {
    const url = this.url.signUp

    const params = needResetParams

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.post(url, data, config)
  }

  async passwordReset (data: IPasswordResetValues): Promise<AxiosResponse> {
    const url = this.url.passwordReset

    return await this._http.post(url, data)
  }

  async passwordResetConfirm (data: IChangePasswordData): Promise<AxiosResponse> {
    const url = this.url.passwordResetConfirm

    return await this._http.post(url, data)
  }
}

export default new AuthService()
