import cn from 'classnames'
import { debounce } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { ASIDE_WIDTH_SET_DELAY, Direction, NAV_ANIMATION_DELAY } from '@const/consts'
import { Path, Section } from '@const/paths'

import UsersService from '@services/users'

import { useAppSelector, useAppDispatch } from '@hooks/redux'

import {
  Aside,
  Button,
  ILibNavItem,
  LibNavItemLevel,
  LibNavigationTheme,
  Navigation
} from '@infologistics/frontend-libraries'
import MenuItem from './components/MenuItem'

import styles from './Menu.module.scss'

import { setMenuItems } from '@store/modules/navigation'
import { resetError404 } from '@store/modules/utils'

import { INavItem } from './types'
import { setUserSettings } from '@store/modules/user';
import { displayErrorNotification } from '@utils/utils';

const Menu: FC = () => {
  const dispatch = useAppDispatch()
  const {
    activeMenu,
    menuItems
  } = useAppSelector((state) => state.navigation)
  const {
    isAuthenticated,
    profile
  } = useAppSelector((state) => state.user)
  const {
    isError404,
    language
  } = useAppSelector((state) => state.utils)
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getExtendedMenuItems = (menuItems: INavItem[], level = LibNavItemLevel.TOP): ILibNavItem[] => {
    const activeMenuPathname = activeMenu || location.pathname

    const activeMenuItems = menuItems.filter(({ route }) => activeMenuPathname.includes(route))

    const activeMenuItemUuid = activeMenuItems.length ? activeMenuItems[0].uuid : ''

    return menuItems.map((item): ILibNavItem => getExtendedMenuItem(item, level, activeMenuItemUuid))
  }

  const getExtendedMenuItem = (
    menuItem: INavItem,
    menuLevel: string,
    activeMenuItemUuid: string
  ): ILibNavItem => {
    const { items, title, uuid } = menuItem

    const isActive = activeMenuItemUuid === uuid
    const isTopLevel = menuLevel === LibNavItemLevel.TOP

    const subMenuItemsForDropdown = items && getExtendedMenuItems(items, LibNavItemLevel.LOW)

    return {
      ...menuItem,
      customNavLink: <MenuItem key={uuid} item={menuItem} />,
      isActive,
      isOpen: !isTopLevel || isActive,
      items: subMenuItemsForDropdown,
      level: menuLevel,
      title: t(title)
    }
  }

  const getNavItems = (): void => {
    const { boxOguid, email, fullName } = profile

    const items: INavItem[] = [
      {
        contentData: {
          content: email,
          heading: fullName
        },
        iconCollapse: 'IconAngle',
        iconCollapseProps: { classes: 'ml-auto icon-collapse', size: 'xs' },
        items: [
          {
            icon: 'IconIncomingAlt',
            iconProps: { classes: 'mr-2' },
            route: Path.ENVELOPES_BOX_OGUID
              .replace(':boxOguid', boxOguid)
              .replace(':directionOrFolder', Direction.INCOMING),
            title: 'envelopes:incoming',
            uuid: uuidv4()
          },
          {
            icon: 'IconOutgoingAlt',
            iconProps: { classes: 'mr-2' },
            route: Path.ENVELOPES_BOX_OGUID
              .replace(':boxOguid', boxOguid)
              .replace(':directionOrFolder', Direction.OUTGOING),
            title: 'envelopes:outgoing',
            uuid: uuidv4()
          }
        ],
        route: Section.ENVELOPES_BOX_OGUID.replace(':boxOguid', boxOguid),
        title: fullName,
        uuid: uuidv4()
      }
    ]

    dispatch(setMenuItems(items))
  }

  const handleNewEnvelopeButtonClick = (): void => {
    navigate(Path.ENVELOPE_NEW)
  }

  const handleResize = (asideWidth: string) => {
    dispatch(setUserSettings({ asideWidth }))

    UsersService.updateUserSettings({ asideWidth })
      .catch(displayErrorNotification)
  }


  const handleWithDebounse = debounce(handleResize, ASIDE_WIDTH_SET_DELAY)

  useEffect(getNavItems, [language])
  useEffect(() => {
    if (!isAuthenticated) return

    if (isError404) {
      dispatch(resetError404())
    }
  }, [location.pathname])

  return (
    <Aside
      onResize={handleWithDebounse}
      minWidth={15}
    >
      <div className='d-flex full-width'>
        <Button
          theme='warning'
          classes={cn('fw-700 my-2 mx-auto', styles.new_envelope_button)}
          onClick={handleNewEnvelopeButtonClick}
        >
          {t('envelopes:new')}
        </Button>
      </div>
      <Navigation
        delay={NAV_ANIMATION_DELAY}
        items={getExtendedMenuItems(menuItems)}
        theme={LibNavigationTheme.ALTERNATIVE}
      />
    </Aside>
  )
}

export default Menu
