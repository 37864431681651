import { IBoxesInitialValues } from '@store/modules/boxes/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { catchAxiosError } from '@utils/utils';

import BoxesService from '@services/boxes'

const initialState: IBoxesInitialValues = {
  webHook: {
    isWebhookEnabled: false,
    webhookUrl: null,
    webhookAuthType: null,
    webhookAuthToken: null,
    webhookEvents: null,
    lastCallInfo: {
      timestamp: '',
      statusCode: '',
      statusMessage: null
    }
  }
}

export const getWebHook = createAsyncThunk(
  'boxes/webHook',
  async (boxOguid: string, { rejectWithValue }) => {
    try {
      const response = await BoxesService.getWebHook(boxOguid)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const boxesSlice = createSlice({
  initialState,
  name: 'boxes',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebHook.fulfilled, (state, action) => {
        if (!action.payload) return

        state.webHook = action.payload
    })
  }
})

const { reducer } = boxesSlice

export default reducer
