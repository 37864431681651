import { omit } from 'lodash'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import EnvelopesService from '@services/envelopes'
import ShelfService from '@services/shelf'

import { setPagination } from '@store/modules/pagination'
import { catchAxiosError, getPagination, getImageDimensions, getDraftEnvelopeData } from '@utils/utils'
import { DraftEnvelopeRecipientsBaseColors, EnvelopeDirection } from '@const/consts'

import { AppState } from '@store/types'
import {
  DraftEnvelopeRecipientSignerType,
  DraftEnvelopeRecipientType,
  IDraftEnvelope,
  IDraftEnvelopeField,
  IDraftEnvelopeMessage,
  IDraftEnvelopePrepositionedMark,
  IDraftEnvelopeRecipient,
  IEnvelopeParams,
  IEnvelopesListParams,
  IEnvelopesState,
  IGetPreview, IGetPreviewParams,
  ISetDraftEnvelopePrepositionedMark
} from './types'
import { Checkbox } from '@hooks/envelopes/types'
import { Nullable } from '@app/types'

const initialState: IEnvelopesState = {
  envelopes: {
    data: [],
    direction: undefined
  },
  checkedEnvelopes: {},
  currentEnvelope: null,
  draftEnvelope: {
    fields: {},
    fieldsIdList: [],
    files: [],
    message: {
      comment: null,
      expirationDateTime: null,
      externalId: null,
      subject: ''
    },
    prepositionedMarks: {},
    prepositionedMarksIdList: [],
    preview: {},
    recipients: {},
    recipientsColors: DraftEnvelopeRecipientsBaseColors,
    recipientsIdList: []
  }
}

export const getEnvelopes = createAsyncThunk(
  'envelopes',
  async ({ direction, boxOguid, page, perPage }: IEnvelopesListParams, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await EnvelopesService.getEnvelopesList({
          direction, boxOguid, page, perPage
        })

      const pagination = getPagination(response.headers)
      dispatch(setPagination(pagination))

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const getEnvelope = createAsyncThunk(
  'envelope',
  async (data: IEnvelopeParams, { rejectWithValue }) => {
    try {
      const response =
        await EnvelopesService.getEnvelope(data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const getPreview = createAsyncThunk<IGetPreview | void, IGetPreviewParams, { state: AppState }>(
  'envelopes/getPreview',
  async ({fileUuid, requestUuid}: IGetPreviewParams, { getState, rejectWithValue }) => {
    const {
      draftEnvelope: {
        preview
      }
    } = getState().envelopes

    const { data } = preview[fileUuid]

    const page = data ? data.length + 1 : 1

    try {
      const response = await ShelfService.preview(fileUuid, String(page), requestUuid)
      const { headers } = response

      const fileCode = response.data
      const mimeType = headers['x-mime-type']

      const dimensions = await getImageDimensions(fileCode, mimeType)

      return {
        dimensions,
        fileCode,
        mimeType,
        orientation: headers['x-page-orientation'],
        totalPages: Number(headers['x-total-pages'])
      }
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const sendEnvelope = createAsyncThunk<string | void, string, { state: AppState }>(
  'envelopes/sendEnvelope',
  async (_, { getState, rejectWithValue }) => {
    const {
      draftEnvelope
    } = getState().envelopes
    const {
      profile: {
        boxOguid
      }
    } = getState().user

    const draftEnvelopeData = getDraftEnvelopeData(draftEnvelope, boxOguid)

    try {
      const response = await EnvelopesService.sendEnvelope(draftEnvelopeData)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const uploadFile = createAsyncThunk(
  'envelopes/uploadFile',
  async (data: FormData, { rejectWithValue }) => {
    try {
      const response = await ShelfService.upload(data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const envelopesSlice = createSlice({
  initialState,
  name: 'envelopes',
  reducers: {
    setCheckedEnvelopes: (state, action: PayloadAction<Checkbox>) => {
      state.checkedEnvelopes = { ...state.checkedEnvelopes, ...action.payload }
    },
    resetEnvelopes: (state) => {
      state.envelopes.data = []
    },
    resetCurrentEnvelope: (state) => {
      state.currentEnvelope = null
    },
    resetCheckedEnvelopes: (state) => {
      state.checkedEnvelopes = {}
    },
    setDirectionEnvelopes: (state, action: PayloadAction<Nullable<EnvelopeDirection | undefined>>) => {
      state.envelopes.direction = action.payload
    },
    removeDraftEnvelopeFile: (state, action: PayloadAction<string>) => {
      const {
        files,
        preview,
        prepositionedMarks,
        prepositionedMarksIdList
      } = state.draftEnvelope

      const fileIndex = files.findIndex((fileUuid) => fileUuid === action.payload)
      const removedMarksIDs = prepositionedMarksIdList.filter((id) => {
        const mark = prepositionedMarks[id]

        return mark.fileIndex === fileIndex
      })

      const newDraftEnvelopeFiles = files.filter((fileUuid) => fileUuid !== action.payload)
      const newDraftEnvelopePreview = omit(preview, [action.payload])

      const newPrepositionedMarks = omit(prepositionedMarks, removedMarksIDs)
      const newPrepositionedMarksIdList = prepositionedMarksIdList.filter((id) => !removedMarksIDs.includes(id))

      state.draftEnvelope.files = newDraftEnvelopeFiles
      state.draftEnvelope.preview = {...newDraftEnvelopePreview}
      state.draftEnvelope.prepositionedMarks = {...newPrepositionedMarks}
      state.draftEnvelope.prepositionedMarksIdList = newPrepositionedMarksIdList
    },
    resetDraftEnvelope: (state) => {
      state.draftEnvelope = { ...initialState.draftEnvelope }
    },
    setDraftEnvelope: (state, action: PayloadAction<IDraftEnvelope>) => {
      state.draftEnvelope = { ...action.payload }
    },
    addDraftEnvelopeRecipient: (state) => {
      const { recipients, recipientsColors, recipientsIdList } = state.draftEnvelope

      const uuid = uuidv4()

      const [ color, ...restRecipientsColors ] = recipientsColors

      const lastRecipient = recipientsIdList.length
        ? recipients[recipientsIdList[recipientsIdList.length - 1]]
        : null

      const draftEnvelopeRecipientTemplate: IDraftEnvelopeRecipient = {
        boxEmail: '',
        color,
        groupNum: lastRecipient ? lastRecipient.groupNum + 1 : 1,
        guestEmail: null,
        isHostMode: false,
        isRequireSelfie: false,
        isRequireSms: false,
        prepositionedMarks: null,
        signer: {
          fullName: '',
          initials: null,
          position: null,
          phone: null
        },
        signingParams: {
          isAllowElectronicSignature: true,
          signerType: DraftEnvelopeRecipientSignerType.PART
        },
        type: DraftEnvelopeRecipientType.SIGNING
      }

      state.draftEnvelope.recipientsColors = restRecipientsColors

      state.draftEnvelope.recipientsIdList = [
        ...recipientsIdList,
        uuid
      ]

      state.draftEnvelope.recipients = {
        ...recipients,
        [uuid]: {...draftEnvelopeRecipientTemplate}
      }
    },
    removeDraftEnvelopeRecipient: (state, action: PayloadAction<string>) => {
      const {
        prepositionedMarks,
        prepositionedMarksIdList,
        recipients,
        recipientsColors,
        recipientsIdList
      } = state.draftEnvelope

      const { color } = recipients[action.payload]
      const removedMarksIDs = prepositionedMarksIdList.filter((id) => {
        const mark = prepositionedMarks[id]

        return mark.recipient === action.payload
      })

      const newDraftEnvelopeRecipientsIdList = recipientsIdList.filter((uuid) => uuid !== action.payload)
      const newDraftEnvelopeRecipients = omit(recipients, [action.payload])
      const newPrepositionedMarks = omit(prepositionedMarks, removedMarksIDs)
      const newPrepositionedMarksIdList = prepositionedMarksIdList.filter((id) => !removedMarksIDs.includes(id))

      state.draftEnvelope.recipients = {...newDraftEnvelopeRecipients}
      state.draftEnvelope.recipientsColors = [...recipientsColors, color]
      state.draftEnvelope.recipientsIdList = newDraftEnvelopeRecipientsIdList
      state.draftEnvelope.prepositionedMarks = {...newPrepositionedMarks}
      state.draftEnvelope.prepositionedMarksIdList = newPrepositionedMarksIdList
    },
    setDraftEnvelopeRecipients: (state, action: PayloadAction<Record<string, IDraftEnvelopeRecipient>>) => {
      state.draftEnvelope.recipients = action.payload
    },
    setDraftEnvelopeRecipientColors: (state, action: PayloadAction<string[]>) => {
      state.draftEnvelope.recipientsColors = action.payload
    },
    setDraftEnvelopeRecipientsIdList: (state, action: PayloadAction<string[]>) => {
      state.draftEnvelope.recipientsIdList = action.payload
    },
    addDraftEnvelopePrepositionedMark: (state, action: PayloadAction<IDraftEnvelopePrepositionedMark>) => {
      const { prepositionedMarks, prepositionedMarksIdList } = state.draftEnvelope

      const uuid = uuidv4()

      state.draftEnvelope.prepositionedMarksIdList = [
        ...prepositionedMarksIdList,
        uuid
      ]

      state.draftEnvelope.prepositionedMarks = {
        ...prepositionedMarks,
        [uuid]: {...action.payload}
      }
    },
    removeDraftEnvelopePrepositionedMark: (state, action: PayloadAction<string>) => {
      const { prepositionedMarks, prepositionedMarksIdList } = state.draftEnvelope

      const newPrepositionedMarksIdList = prepositionedMarksIdList.filter((uuid) => uuid !== action.payload)
      const newPrepositionedMarks = omit(prepositionedMarks, [action.payload])

      state.draftEnvelope.prepositionedMarks = {...newPrepositionedMarks}
      state.draftEnvelope.prepositionedMarksIdList = newPrepositionedMarksIdList
    },
    setDraftEnvelopePrepositionedMark: (state, action: PayloadAction<ISetDraftEnvelopePrepositionedMark>) => {
      const { prepositionedMarks } = state.draftEnvelope

      const { mark, uuid } = action.payload

      state.draftEnvelope.prepositionedMarks = {
        ...prepositionedMarks,
        [uuid]: {
          ...prepositionedMarks[uuid],
          ...mark
        }
      }
    },
    setDraftEnvelopePrepositionedMarks: (state, action: PayloadAction<Record<string, IDraftEnvelopePrepositionedMark>>) => {
      state.draftEnvelope.prepositionedMarks = action.payload
    },
    setDraftEnvelopePrepositionedMarksIdList: (state, action: PayloadAction<string[]>) => {
      state.draftEnvelope.prepositionedMarksIdList = action.payload
    },
    setDraftEnvelopeMessage: (state, action: PayloadAction<IDraftEnvelopeMessage>) => {
      state.draftEnvelope.message = action.payload
    },
    addDraftEnvelopeField: (state, action: PayloadAction<IDraftEnvelopeField>) => {
      const { fields, fieldsIdList } = state.draftEnvelope

      const uuid = uuidv4()

      state.draftEnvelope.fieldsIdList = [
        ...fieldsIdList,
        uuid
      ]

      state.draftEnvelope.fields = {
        ...fields,
        [uuid]: {...action.payload}
      }
    },
    removeDraftEnvelopeField: (state, action: PayloadAction<string>) => {
      const { fields, fieldsIdList } = state.draftEnvelope

      const newFieldsIdList = fieldsIdList.filter((uuid) => uuid !== action.payload)
      const newFields = omit(fields, [action.payload])

      state.draftEnvelope.fields = {...newFields}
      state.draftEnvelope.fieldsIdList = newFieldsIdList
    },
    setDraftEnvelopeFields: (state, action: PayloadAction<Record<string, IDraftEnvelopeField>>) => {
      state.draftEnvelope.fields = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnvelopes.fulfilled, (state, action) => {
        if (!action.payload) return

        state.envelopes.data = action.payload
      })
      .addCase(getEnvelope.fulfilled, (state, action) => {
        if (!action.payload) return

        state.currentEnvelope = action.payload
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        if (!action.payload) return

        const { files } = state.draftEnvelope

        const {
          meta: {
            arg
          },
          payload
        } = action

        const { name } = arg.get('file') as File

        state.draftEnvelope.files = [ ...files, payload ]
        state.draftEnvelope.preview[payload] = {
          data: [],
          fileName: name,
          isAvailable: true,
          total: 0
        }
      })
      .addCase(getPreview.fulfilled, (state, action) => {
        if (!action.payload) return

        const {
          meta: {
            arg: {
              fileUuid
            }
          },
          payload: {
            dimensions,
            fileCode,
            mimeType,
            orientation,
            totalPages
          }
        } = action

        const preview = state.draftEnvelope.preview[fileUuid]

        const { data, total } = preview

        state.draftEnvelope.preview[fileUuid] = fileCode
          ? {
            ...preview,
            data: [
              ...data,
              {
                dimensions,
                fileCode,
                mimeType,
                orientation
              }
            ],
            total: total === 0 ? totalPages : total
          } : {
            ...preview,
            isAvailable: false
          }
      })
      .addCase(sendEnvelope.fulfilled, (state) => {
        state.draftEnvelope = {
          ...initialState.draftEnvelope
        }
      })
  }
})

const { actions, reducer } = envelopesSlice

export const {
  setCheckedEnvelopes,
  resetCheckedEnvelopes,
  setDirectionEnvelopes,
  resetEnvelopes,
  resetCurrentEnvelope,
  removeDraftEnvelopeFile,
  resetDraftEnvelope,
  setDraftEnvelope,
  addDraftEnvelopeRecipient,
  removeDraftEnvelopeRecipient,
  setDraftEnvelopeRecipients,
  setDraftEnvelopeRecipientColors,
  setDraftEnvelopeRecipientsIdList,
  addDraftEnvelopePrepositionedMark,
  removeDraftEnvelopePrepositionedMark,
  setDraftEnvelopePrepositionedMark,
  setDraftEnvelopePrepositionedMarks,
  setDraftEnvelopePrepositionedMarksIdList,
  setDraftEnvelopeMessage,
  addDraftEnvelopeField,
  removeDraftEnvelopeField,
  setDraftEnvelopeFields
} = actions

export default reducer
