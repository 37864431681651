import React, { FC } from 'react'

import { privateRoutes } from '@app/routes'
import { RenderRoutes } from '@common/Routing'
import { useAppSelector } from '@hooks/redux'

import { Layout, Loader } from '@infologistics/frontend-libraries'

const LayoutWrapper: FC = () => {
  const { isLoading } = useAppSelector((state) => state.loader)
  const { isMenuVisible } = useAppSelector((state) => state.utils)

  const widthClass = isMenuVisible ? undefined : 'fl-layout-full'

  return (
    <Layout externalClass={widthClass}>
      <RenderRoutes routes={privateRoutes} />
      <Loader loading={isLoading} />
    </Layout>
  )
}

export default LayoutWrapper
