import cn from 'classnames'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Content } from '@infologistics/frontend-libraries'

import { useAppDispatch } from '@hooks/redux'

import { resetError404 } from '@store/modules/utils'

import styles from './Page404.module.scss'

const Page404: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleReturnButtonClick = (): void => {
    dispatch(resetError404())
  }

  return (
    <Content classes='d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center'>
        <div className={styles.content}>
          <p className={cn('fw-700', 'text-brand', 'mb-4', styles.error_code)}>404</p>
          <p className='fw-600 mb-2'>{t('error:404.title')}</p>
          <div className='text-muted font-sm mb-6'>
            <p className='mb-1'>{t('error:404.title_reasons')}</p>
            <ul className='pl-3 mb-2'>
              <li>{t('error:404.one')}</li>
              <li>{t('error:404.two')}</li>
              <li>{t('error:404.three')}</li>
            </ul>
            <p className='mb-0'>{t('error:404.footer')}</p>
          </div>
          <Button theme='primary' onClick={handleReturnButtonClick}>
            {t('error:main')}
            {/*TODO: When navigation is done, and it is clear where to go - add a link here */}
          </Button>
        </div>
        <img src='/assets/img/error.svg' width='493' height='392' alt='' />
      </div>
    </Content>
  )
}

export default Page404
