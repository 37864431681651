import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { INavItem } from '@common/Layout/components/Menu/types';
import { IBadges, INavigationState } from './types'

const initialState: INavigationState = {
  activeMenu: '',
  badges: {},
  menuItems: []
}

export const navigationSlice = createSlice({
  initialState,
  name: 'navigation',
  reducers: {
    setActiveMenu: (state, action: PayloadAction<string>) => {
      state.activeMenu = action.payload
    },
    setBadges: (state, action: PayloadAction<IBadges>) => {
      state.badges = { ...action.payload }
    },
    setMenuItems: (state, action: PayloadAction<INavItem[]>) => {
      state.menuItems = [...action.payload]
    }
  }
})

const { actions, reducer } = navigationSlice

export const { setActiveMenu, setBadges, setMenuItems } = actions

export default reducer
