import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import AuthService from '@services/auth'
import TokensService from '@services/tokensService'
import UsersService from '@services/users'

import { BooleanValue } from '@const/consts'

import { catchAxiosError, clearLocalStorage } from '@utils/utils'

import { IPasswordResetThunkArg, IPasswordResetConfirmThunkArg, ISigninThunkArg, IUserState } from './types'
import { NotificationLevel } from '@const/consts';
import { Nullable } from '@infologistics/frontend-libraries';

const initialState: IUserState = {
  isAuthenticated: false,
  profile: {
    boxOguid: '',
    companyAddress: null,
    companyName: null,
    companyZip: null,
    electronicSignatureImage: null,
    email: '',
    firstName: '',
    fullName: '',
    initials: '',
    isPhoneConfirmed: false,
    lastName: '',
    locale: '',
    middleName: null,
    oguid: '',
    phone: null,
    position: null,
    timeZone: '',
    isEmailNotification: false,
    isDeviceNotification: false,
    notificationLevel: NotificationLevel.ONLY_INCOMING
  },
  signatures: {
    initialSignature: null,
    uploadSignature: null,
    drawSignatureFull: null,
    drawSignatureTrim: null,
    newSignature: null
  },
  settings: {
    asideWidth: window.getComputedStyle(document.documentElement).getPropertyValue('--aside-width')
  }
}

export const signIn = createAsyncThunk(
  'user/signIn',
  async ({ data }: ISigninThunkArg, { rejectWithValue }) => {
    try {
      const response = await AuthService.signIn(data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const getCurrentUser = createAsyncThunk(
  'user/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UsersService.getCurrentUser()

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const getSettingsUser = createAsyncThunk(
  'user/getProfileSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UsersService.getUserSettings()

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const passwordReset = createAsyncThunk(
  'user/passwordReset',
  async (data: IPasswordResetThunkArg, { rejectWithValue }) => {
    try {
      const response = await AuthService.passwordReset(data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const passwordResetConfirm = createAsyncThunk(
  'user/passwordResetConfirm',
  async (data: IPasswordResetConfirmThunkArg, { rejectWithValue }) => {
    try {
      const response = await AuthService.passwordResetConfirm(data)

      return response.data
    } catch (err) {
      return catchAxiosError(err, rejectWithValue)
    }
  }
)

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    signOut: (state, action: PayloadAction<string>) => {
      // Removing
      // - data from localStorage, including the user and the 'Remember me' flag, except:
      // -- current open tabs with the application
      // -- and other significant data
      clearLocalStorage()

      // tokens from cookies
      TokensService.clearCookies(action.payload)
    },
    setInitialSignature: (state, action: PayloadAction<Nullable<string>>) => {
      state.signatures.initialSignature = action.payload
    },
    setUploadSignature: (state, action: PayloadAction<Nullable<string>>) => {
      state.signatures.uploadSignature = action.payload
    },
    setFullDrawSignature: (state, action: PayloadAction<Nullable<string>>) => {
      state.signatures.drawSignatureFull = action.payload
    },
    setTrimDrawSignature: (state, action: PayloadAction<Nullable<string>>) => {
      state.signatures.drawSignatureTrim = action.payload
    },
    setNewSignature: (state, action: PayloadAction<Nullable<string>>) => {
      state.signatures.newSignature = action.payload
    },
    resetSignatures: (state) => {
      state.signatures = {
        initialSignature: null,
        uploadSignature: null,
        drawSignatureFull: null,
        drawSignatureTrim: null,
        newSignature: null
      }
    },
    setUserSettings: (state, action: PayloadAction<object>) => {
      state.settings = { ...state.settings, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        if (!action.payload) return

        const {
          meta: {
            arg: {
              remember
            }
          },
          payload
        } = action

        // Check whether authorization was completed earlier on another tab
        const user = TokensService.getUserFromCookies()

        // If it was, then delete authorization data
        if (user) {
          TokensService.clearCookies(user)
        }

        // Saving new data ('Remember me' flag, user and tokens)
        const isRememberToService = BooleanValue[String(remember).toUpperCase()]

        TokensService.setCookies(payload, isRememberToService)
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        if (!action.payload) return

        state.profile = action.payload

        const { isAuthenticated } = state

        if (!isAuthenticated) {
          state.isAuthenticated = true
        }
      })
      .addCase(getSettingsUser.fulfilled, (state, action) => {
        if (!action.payload) return

        state.settings = action.payload
      })
  }
})

const { actions, reducer } = userSlice

export const {
  signOut,
  setInitialSignature,
  setUploadSignature,
  setFullDrawSignature,
  setTrimDrawSignature,
  setNewSignature,
  resetSignatures,
  setUserSettings
} = actions

export default reducer
