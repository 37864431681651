import React, { FC } from 'react';
import styles from '../EnvelopeItem.module.scss';

import { useTranslation } from 'react-i18next';

import { Label } from '@infologistics/frontend-libraries';
import { flowStateSettings } from '@views/envelopes/consts';

import { EnvelopeFlowState } from '@const/consts';

import { IEnvelopeStatusProps as IProps } from '@views/envelopes/types';

const EnvelopeStatus: FC<IProps> = ({ flowState, taskCompletedCount, taskTotalCount }) => {
  const { t } = useTranslation(['envelopes'])

  const { title, theme } = flowStateSettings[flowState]

  return (
    <td>
      <Label
        classes={styles.labels}
        status={theme}
      >
        { t(title) }
      </Label>
      {
        flowState === EnvelopeFlowState.IN_PROGRESS && (
          <div className='text-muted'>
            {`${taskCompletedCount} / ${taskTotalCount} ${t('envelopes:done')}`}
          </div>
        )
      }
    </td>
  )
}

export default EnvelopeStatus
