import { lazy } from 'react';

import { IRoute } from '@common/Routing/types';
import { getLazyComponent } from '@utils/utils';
import { Path } from '@const/paths';

export { default as EnvelopesHead } from './components/EnvelopesHead/EnvelopesHead'
export { default as EnvelopesTable } from './components/EnvelopesTable/EnvelopesTable'
export { default as EnvelopeItem } from './components/EnvelopesTable/EnvelopeItem/EnvelopeItem'

const Envelopes = lazy(() => import('./components/Envelopes'))
const EnvelopesCard = lazy(() => import('./components/EnvelopeCard/EnvelopeCard'))
const NewEnvelope = lazy(() => import('./components/NewEnvelope'))

export const routes: IRoute[] = [
  {
    element: getLazyComponent(Envelopes),
    name: 'envelopes/fast-access',
    path: Path.ENVELOPES_FAST_ACCESS
  },
  {
    element: getLazyComponent(Envelopes),
    name: 'envelopes/box-oquid',
    path: Path.ENVELOPES_BOX_OGUID
  },
  {
    element: getLazyComponent(Envelopes),
    name: 'envelopes/filter',
    path: Path.ENVELOPES_FILTER
  },
  {
    element: getLazyComponent(EnvelopesCard),
    name: 'envelope',
    path: Path.ENVELOPE
  },
  {
    element: getLazyComponent(NewEnvelope),
    name: 'NewEnvelope',
    path: Path.ENVELOPE_NEW
  }
]
