import React, { FC, useState } from 'react';
import styles from './EnvelopesHead.module.scss'
import cn from 'classnames';

import { Button, Input, Refresh } from '@infologistics/frontend-libraries';

import { IEnvelopesHeadProps as IProps } from '@views/envelopes/types';
import { useTranslation } from 'react-i18next';

const EnvelopesHead: FC<IProps> = ({ loadEnvelopes }) => {
  const { t } = useTranslation(['envelopes', 'common'])
  const [value, setValue] = useState<string>('')

  return (
    <div className='d-flex align-items-center mb-4'>
      <Refresh
        onClick={loadEnvelopes}
        title={t('common:refresh')}
      />
      <h1 className='ml-4 mb-0'>
        { t('envelopes:title') }
      </h1>
      <Input
        name='envelopesSearch'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('common:search')}
        leftIcon='IconSearch'
        classes={{
          wrapper: cn(styles.input_wrapper, 'ml-auto mr-4'),
        }}
      />
      <Button>
        {t('common:filters')}
      </Button>
    </div>
  );
}

export default EnvelopesHead
