import React, { FC } from 'react';

import { IEnvelopeProps as IProps } from '@views/envelopes/types';

import EnvelopeCheckbox from './EnvelopeCheckbox';
import EnvelopeSubject from './EnvelopeSubject';
import EnvelopeStatus from './EnvelopeStatus';
import EnvelopLastChange from './EnvelopeLastChange';
import EnvelopeActions from './EnvelopeActions';
import EnvelopeIcons from '@views/envelopes/components/EnvelopesTable/EnvelopeItem/EnvelopeIcons';

const EnvelopeItem: FC<IProps> = ({ envelope, checkbox, selectedCheckbox }) => {
  const {
    oguid,
    directions,
    allowedActions,
    subject,
    recipients,
    flowState,
    taskCompletedCount,
    taskTotalCount,
    lastActionDateTime
  } = envelope

  return (
    <tr>
      <EnvelopeCheckbox
        oguid={oguid}
        checkbox={checkbox}
        selectedCheckbox={selectedCheckbox}
      />
      <EnvelopeIcons
        directions={directions}
        allowedActions={allowedActions}
      />
      <EnvelopeSubject
        subject={subject}
        recipients={recipients}
        oguid={oguid}
      />
      <EnvelopeStatus
        flowState={flowState}
        taskCompletedCount={taskCompletedCount}
        taskTotalCount={taskTotalCount}
      />
      <EnvelopLastChange
        lastActionDateTime={lastActionDateTime}
      />
      <EnvelopeActions
        oguid={oguid}
        allowedActions={allowedActions}
        subject={subject}
      />
    </tr>
  )
}

export default EnvelopeItem
