import { useEffect, useState } from 'react';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setDirectionEnvelopes } from '@store/modules/envelopes';

import { EnvelopeDirection, Direction } from '@const/consts';

import { Nullable } from '@infologistics/frontend-libraries';

export const useGetParams = () => {
  const { direction } = useAppSelector(state => state.envelopes.envelopes)
  const dispatch = useAppDispatch()

  const [ folder, setFolder ] = useState<Nullable<string>>(null)
  const [ page, setPage ] = useState<Nullable<string>>(null)
  const [ perPage, setPerPage ] = useState<Nullable<string>>(null)
  const [ collectedParams, setCollectedParams ] = useState<boolean>(false)

  const { search, pathname } = useLocation()
  const [ searchParams ] = useSearchParams()
  const {
    directionParam,
    boxOguid,
    directionOrFolder,
    oguid
  } = useParams()

  useEffect(() => {
    const isDirection = (value: string | undefined): Nullable<EnvelopeDirection> => {
      if (
        value !== Direction.INCOMING &&
        value !== Direction.OUTGOING
      ) return null

      return (
        value === Direction.INCOMING ?
          EnvelopeDirection.INCOMING :
          EnvelopeDirection.OUTGOING
      )
    }

    const getDirection =
      isDirection(directionParam) || isDirection(directionOrFolder)

    const getFolder =
      !isDirection(directionOrFolder) && directionOrFolder ?
        directionOrFolder : null

    const getPage = searchParams.get('page') || null
    const getSetPerPage = localStorage.getItem(pathname) || searchParams.get('perPage') || null

    dispatch(setDirectionEnvelopes(getDirection))
    setFolder(getFolder)
    setPage(getPage)
    setPerPage(getSetPerPage)
    setCollectedParams(true)
  }, [search, directionOrFolder, boxOguid, oguid])

  return {
    direction,
    boxOguid,
    folder,
    page,
    perPage,
    collectedParams,
    oguid,
    setCollectedParams
  }
}


