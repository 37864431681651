import { ILoaderState } from './types'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ILoaderState = {
  isLoading: false
}

export const loaderSlice = createSlice({
  initialState,
  name: 'loader',
  reducers: {
    closeLoader: (state) => {
      state.isLoading = false
    },
    showLoader: (state) => {
      state.isLoading = true
    }
  }
})

const { actions, reducer } = loaderSlice

export const { closeLoader, showLoader } = actions

export default reducer
