import { routes as auth } from '@views/auth'
import { routes as envelopes } from '@views/envelopes'
import { routes as profile } from '@views/profile'

import { IRoute } from '@common/Routing/types'

export const authRoutes: IRoute[] = [...auth]

export const privateRoutes: IRoute[] = [
  ...envelopes,
  ...profile
]
