import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from 'axios'

import i18n from '@app/i18n'

import TokensService from '@services/tokensService'

import store from '@store/configureStore'

import { BrowserStorage, ErrorCode } from '@const/consts'

import { getBaseUrl } from '@utils/utils'
import { setError404 } from '@store/modules/utils'

import { ICanceler } from './types'

class Http {
  private isNeedResetError = {}
  private axiosEvents: {
    [key: string]: ICanceler
  } = {}

  private readonly axiosInstance: AxiosInstance = axios.create({
    baseURL: getBaseUrl(),
    responseType: 'json'
  })

  constructor () {
    this.addInterceptors()
  }

  private addInterceptors (): void {
    this.axiosInstance.interceptors.request.use((config) => {
      const { params, url } = config
      if (params?.isNeedResetError && url) {
        this.isNeedResetError[url] = true

        delete params.isNeedResetError
      }

      if (params?.requestUuid) {
        delete params.requestUuid
      }

      return config
    })

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // If
        // - the authorized user is different from the one saved in cookies
        // - or there is no user saved in cookies at all,
        // then clearing the authorization data and then displaying the authorization form
        const { oguid: user } = store.getState().user.profile
        const userFromService = TokensService.getUserFromCookies()

        if (!!user && (!userFromService || (userFromService && user !== userFromService))) {
          TokensService.clearCookies(user)

          return document.location.reload()
        }

        const { url } = response.config

        if (url) {
          delete this.isNeedResetError[url]
        }

        return response
      },
      async (error: any) => {
        const {
          config,
          response: { status }
        } = error
        const originalRequest = config

        if (originalRequest) {
          const { url } = originalRequest

          if ((url.includes('signin') || url.includes('password-reset')) && status === ErrorCode.NOT_AUTH) {
            return Promise.reject(error)
          }
        }

        if (status === ErrorCode.NOT_AUTH) {
          const { oguid: user } = store.getState().user.profile
          const userFromService = TokensService.getUserFromCookies()

          // If "Remember me" flag exists, then an attempt to update tokens based on the refresh token
          const isCurrentUser = userFromService && (!user || (!!user && user === userFromService))

          // If the authorized user matches the one stored in cookies,
          // then an attempt to update tokens based on the refresh token
          if (isCurrentUser) {
            const refreshToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_REFRESH, user)

            if (refreshToken) {
              originalRequest._retry = true

              await TokensService.memoized(refreshToken)

              const newAccessToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, user)
              originalRequest.headers['Access-Token'] = newAccessToken

              const response = await this.axiosInstance(originalRequest)

              return response
            }
          }

          // If the user does not match the saved one, then:
          // - clear cookies
          userFromService && TokensService.clearCookies(userFromService)

          // - display the authorization form on other tabs when performing any action with an API request
          document.location.reload()

          return await Promise.reject(error)
        }

        const { url } = originalRequest

        if (status === ErrorCode.NOT_FOUND && !this.isNeedResetError[url]) {
          store.dispatch(setError404())
        }

        delete this.isNeedResetError[url]

        return await Promise.reject(error)
      }
    )
  }

  private setHeaders (config: AxiosRequestConfig): void {
    const { headers = {} } = config

    const { oguid: user } = store.getState().user.profile
    const userFromService = TokensService.getUserFromCookies()

    const isCurrentUser = userFromService && (!user || (!!user && user === userFromService))

    const accessToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)

    if (isCurrentUser && accessToken) {
      headers['Access-Token'] = accessToken
    }

    config.headers = {
      ...headers,
      ['Accept-Language']: i18n.language,
      ['X-Time-Zone']: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    config.baseURL = getBaseUrl()
  }

  private setCancelToken (config: AxiosRequestConfig, id: string): void {
    config.cancelToken = new axios.CancelToken((canceler: Canceler) => {
      this.axiosEvents[id] = { canceler, id }
    })
  }

  public removeAxiosEvent (id: string): void {
    const event = this.axiosEvents[id]
    event?.canceler()
    delete this.axiosEvents[id]
  }

  async get (url: string, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    const requestUuid = config.params?.requestUuid
    requestUuid && this.setCancelToken(config, requestUuid)

    return await this.axiosInstance?.get(url, config).then((response) => {
      requestUuid && delete this.axiosEvents[requestUuid]

      return response
    })
  }

  async post (url: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    const requestUuid = config.params?.requestUuid
    requestUuid && this.setCancelToken(config, requestUuid)

    return await this.axiosInstance?.post(url, data, config).then((response) => {
      requestUuid && delete this.axiosEvents[requestUuid]

      return response
    })
  }

  async put (url: string, data: any, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    const requestUuid = config.params?.requestUuid
    requestUuid && this.setCancelToken(config, requestUuid)

    return await this.axiosInstance?.put(url, data, config).then((response) => {
      requestUuid && delete this.axiosEvents[requestUuid]

      return response
    })
  }

  async delete (url: string, config: AxiosRequestConfig = {}): Promise<any> {
    this.setHeaders(config)

    const requestUuid = config.params?.requestUuid
    requestUuid && this.setCancelToken(config, requestUuid)

    return await this.axiosInstance?.delete(url, config).then((response) => {
      requestUuid && delete this.axiosEvents[requestUuid]

      return response
    })
  }
}

export default new Http()
