import { IUtilsState } from './types'
import { BrowserStorage, Instance, Languages } from '@const/consts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IUtilsState = {
  instance: window.localStorage.getItem(BrowserStorage.INSTANCE) as Instance ?? Instance.GLOBAL,
  isError404: false,
  isHeaderVisible: true,
  isMenuVisible: true,
  language: window.localStorage.getItem(BrowserStorage.LANGUAGE) ?? Languages.EN
}

export const utilsSlice = createSlice({
  initialState,
  name: 'utils',
  reducers: {
    resetError404: (state) => {
      state.isError404 = false
    },
    setError404: (state) => {
      state.isError404 = true
    },
    setHeaderVisible: (state, action: PayloadAction<boolean>) => {
      state.isHeaderVisible = action.payload
    },
    setInstance: (state, action) => {
      state.instance = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setMenuVisible: (state, action: PayloadAction<boolean>) => {
      state.isMenuVisible = action.payload
    }
  }
})

const { actions, reducer } = utilsSlice

export const { resetError404, setError404, setHeaderVisible, setInstance, setLanguage, setMenuVisible } = actions

export default reducer
