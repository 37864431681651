import React, {
  FC,
  ReactElement,
  Fragment,
  useRef,
  MouseEvent
} from 'react';
import styles from '../EnvelopeItem.module.scss'
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import {
  Button,
  Ellipsis,
  Popover,
} from '@infologistics/frontend-libraries';

import { EnvelopeAllowedActionsName } from '@const/consts';

import { useAllowedActions } from '@hooks/envelopes/useAllowedActions';

import { IEnvelopeSubjectProps as IProps } from '@views/envelopes/types';

const EnvelopeSubject: FC<IProps> = ({ subject, recipients, oguid }) => {
  const { t } = useTranslation(['envelopes', 'common'])

  const { handleClickAllowedActions } = useAllowedActions()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { fullName: recipientFirst } = recipients[0]
  const recipientSecond = recipients[1] ? `, ${recipients[1].fullName}` : ''
  const recipientThird = recipients[2] ? recipients[2].fullName : null

  const handleClick = (e: MouseEvent): void => {
    if (
      e.target instanceof Node &&
      buttonRef.current?.contains(e.target)
    ) return

    const option = { name: EnvelopeAllowedActionsName.OPEN }
    handleClickAllowedActions({ option, oguid })
  }

  const getPopoverButtonCombonent = (): ReactElement =>
    <Button
      ref={buttonRef}
      classes='text-primary'
      linkStyle='none'
      theme='text'
    >
      {` + ${recipients.length - 2} ${t('common:more')}`}
    </Button>

  const getRecipients = (): ReactElement =>
    <span className={cn('grid relative grid-gap-x-none lh-2 align-items-baseline', styles.recipients)}>
      <Ellipsis externalClass='mr-1 text-muted'>
        {`${t('envelopes:to')}: ${recipientFirst}${recipientSecond}`}
      </Ellipsis>
      {
        recipientThird && (
          <Fragment>
            <Popover
              buttonComponent={getPopoverButtonCombonent()}
              containerClasses={cn(styles.tooltip, 'p-2')}
              buttonClasses='d-flex'
              openButtonClasses='d-flex'
              withoutArrow
            >
              {
                <ul className="m-0 pl-4 text-nowrap text-color">
                  {
                    recipients.map((recipient, index) =>
                      <li
                        key={index}
                        className={cn('font-xs relative', styles.tooltip_item)}
                      >
                        { recipient.fullName }
                      </li>
                    )
                  }
                </ul>
              }
            </Popover>
          </Fragment>
        )
      }
    </span>

  return (
    <td
      className={cn(styles.subject_container, 'pointer')}
      onClick={handleClick}
    >
      <div className='d-flex align-items-center'>
        <div className='grid full-width grid-gap-y-none'>
          <Ellipsis>{subject}</Ellipsis>
          { getRecipients() }
        </div>
      </div>
    </td>
  )
}

export default EnvelopeSubject
