import { IStartupState } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: IStartupState = {
  isLoaded: false
}

export const startupSlice = createSlice({
  initialState,
  name: 'startup',
  reducers: {
    resetStartup: () => (
      {...initialState}
    ),
    setLoaded: (state, action: PayloadAction<boolean | undefined>) => {
      state.isLoaded = action.payload ?? true
    }
  }
})

const { actions, reducer } = startupSlice

export const { resetStartup, setLoaded } = actions

export default reducer
