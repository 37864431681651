import { AxiosRequestConfig, AxiosResponse } from 'axios'
import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'

class ShelfService extends AbstractHttpService {
  private readonly url = urls.shelf

  async preview (fileOguid: string, page: string, requestUuid?: string): Promise<AxiosResponse<string>> {
    const url = this.url.preview
      .replace('{{fileOguid}}', fileOguid)
      .replace('{{page}}', page)

    const config: AxiosRequestConfig = requestUuid ? { params: { requestUuid } } : {}

    return await this._http.get(url, config)
  }

  async upload (data: FormData): Promise<AxiosResponse<string>> {
    const url = this.url.upload

    return await this._http.post(url, data)
  }
}

export default new ShelfService()
