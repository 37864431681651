import { AxiosRequestConfig, AxiosResponse } from 'axios'
import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { needResetParams } from '@const/consts'

class MetadataService extends AbstractHttpService {
  private readonly url = urls.metadata

  async getLocales (): Promise<AxiosResponse<Record<string, string>>> {
    const url = this.url.locales

    const params = needResetParams

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }

  async getTimeZones (): Promise<AxiosResponse<Record<string, string>>> {
    const url = this.url.timeZones

    const params = needResetParams

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }
}

export default new MetadataService()

