import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Notifications } from '@infologistics/frontend-libraries'

import { authRoutes } from '@app/routes'
import { getRoutesList } from '@utils/utils'

import { RedirectToStartPage } from '@common/Routing'

import AppLayout from '@common/Layout/components/AppLayout'

const LayoutRouter: FC = () => (
  <BrowserRouter>
    <Notifications />
    <Routes>
      <Route index element={<RedirectToStartPage />} />
      {getRoutesList(authRoutes)}
      <Route path='*' element={<AppLayout />} />
    </Routes>
  </BrowserRouter>
)

export default LayoutRouter
