import React, { FC, ReactNode, memo, useEffect } from 'react';
import styles from './EnvelopesTable.module.scss'

import { useTranslation } from 'react-i18next';

import {
  Checkbox, getCheckboxCheckedValue,
  LibFixedType,
  LibScrollPositionType,
  Table
} from '@infologistics/frontend-libraries'

import { useAppDispatch, useAppSelector } from '@hooks/redux';

import { resetEnvelopes } from '@store/modules/envelopes';

import { EnvelopeItem } from '@views/envelopes';
import { useCheckboxes } from '@hooks/envelopes';

import EnvelopesPagination from './EnvelopesPagination';

import { IEnvelopesTableProps as IProps } from '@views/envelopes/types';

const EnvelopesTable: FC<IProps> = ({ envelopes }) => {
  const { t } = useTranslation(['envelopes'])
  const {
    headCheckbox,
    setHeadCheckbox,
    checkedEnvelopes,
    selectedCheckbox
  } = useCheckboxes()

  const { asideWidth } = useAppSelector(state => state.user.settings)

  const dispatch = useAppDispatch()

  const selectAllCheckbox = (): ReactNode =>
    <Checkbox
      checked={getCheckboxCheckedValue(headCheckbox ?? false)}
      name='checkAllEnvelopes'
      onChange={() => setHeadCheckbox(!headCheckbox)}
      value='selectAllEnvelopes'
    />

  const headings = [
    { class: styles.checkbox, content: selectAllCheckbox() },
    { class: styles.icons, content: '' },
    { content: t('envelopes:table:head:subject') },
    { class: styles.status, content: t('envelopes:table:head:status') },
    { class: styles.lastChange, content: t('envelopes:table:head:lastChange') },
    { class: styles.actions, content: t('envelopes:table:head:actions') },
  ]

  useEffect(() => {
    return () => {
      dispatch(resetEnvelopes())
    }
  }, [])

  return (
    <div className='overflow-y-scroll relative scrollbar-content'>
      <Table
        fixedType={LibFixedType.SCROLLING_TAB}
        headings={headings}
        customScroll
        pagination={<EnvelopesPagination />}
        scrollPosition={LibScrollPositionType.FIXED}
        scrollContentClasses='mb-8'
        isUpdateTableY
        recalcTrigger={asideWidth}
      >
        {
          envelopes.map(envelope => (
            <EnvelopeItem
              key={envelope.oguid}
              envelope={envelope}
              checkbox={checkedEnvelopes[envelope.oguid]}
              selectedCheckbox={selectedCheckbox}
            />
          ))
        }
      </Table>
    </div>
  )
}

const EnvelopesTableMemo = memo(EnvelopesTable)

export default EnvelopesTableMemo
