import { lazy } from 'react';

import { IRoute } from '@common/Routing/types';
import { getLazyComponent } from '@utils/utils';
import { RouteName } from '@const/paths';

const Profile = lazy(() => import('./components/Profile'))

export const routes: IRoute[] = [
  {
    element: getLazyComponent(Profile),
    name: 'profile',
    path: RouteName.PROFILE
  }
]
