import React, { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Path, RouteName } from '@const/paths'
import { Direction } from '@const/consts';

import { useAppSelector } from '@hooks/redux'
import { createUrl } from '@utils/utils'

const RedirectToStartPage: FC = () => {
  const { pathname, search } = useLocation()
  const currentLocation = `${pathname}${search}`
  const {
    isAuthenticated,
    profile: {
      boxOguid
    }
  } = useAppSelector((state ) => state.user)

  if (!isAuthenticated) {
    return (
      <Navigate
        replace
        to={{
          pathname: RouteName.SIGN_IN,
        }}
        state={{ from: currentLocation }}
      />
    )
  }

  const section = Path.ENVELOPES_BOX_OGUID
    .replace(':boxOguid', boxOguid)
    .replace(':directionOrFolder', Direction.INCOMING)

  return <Navigate to={createUrl(section)} />
}

export default RedirectToStartPage
