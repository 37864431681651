import React, { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { RouteName } from '@const/paths'

import { useAppSelector } from '@hooks/redux'

import { IPrivateRouteWrapper as IProps } from './types'

const PrivateRouteWrapper: FC<IProps> = ({ children }) => {
  const { pathname, search } = useLocation()
  const currentLocation = `${pathname}${search}`
  const { isAuthenticated } = useAppSelector((state) => state.user)

  return (
    isAuthenticated ? (
      children
    ) : (
      <Navigate
        replace
        to={{
          pathname: RouteName.SIGN_IN,
        }}
        state={{ from: currentLocation }}
      />
    )
  )
}

export default PrivateRouteWrapper
